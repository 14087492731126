import { pathsNewYearGame } from './pathsNewYearGame'
import { pathsTreasureIslandGame } from './pathsTreasureIslandGame'
import { pathsKDPayGame } from './pathsKDPayGame'

const pathsByGameId = {
  1: pathsNewYearGame,
  2: pathsTreasureIslandGame,
  3: pathsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PATHS = pathsByGameId[gameId]
