import styled from 'styled-components'

export const BoardKeyPreviewWrapperTreasureIslandGame = styled.div`
  position: relative;
  z-index: 20;
  width: ${({ $width }) => $width + '%'};
  max-width: 100px;
  transform: translate(0, 0);

  background-image: url(/img/treasureIslandGame/shovel_glowing_board.png);
  background-size: 132% auto;
  background-position: 32% 63%;
  background-repeat: no-repeat;

  &:before {
    content: '';

    display: block;
    padding-top: 100%;
  }

  @media (min-width: 768px) {
    width: 100px;
  }

  @media (min-width: 1903px) {
    width: 4.2vw;
  }
`
