import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setUrlParams } from '../store/slices/userSlice'

const ENVIRONMENT = process.env.REACT_APP_ENV

export const useUserManager = () => {
  const dispatch = useDispatch()
  const urlSearchParamsLocal = useSelector(state => state.user.urlSearchParams)

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const urlParamsResult = {}
    for (let param of urlSearchParams) {
      urlParamsResult[param[0]] = param[1]
    }
    const sessionID = urlSearchParams.get('session_id')
    const userID = urlSearchParams.get('user_id')

    const localSessionID = urlSearchParamsLocal?.session_id
    const localUserID = urlSearchParamsLocal?.user_id

    if ((!sessionID && !localSessionID) || (!userID && !localUserID)) {
      const pathname = window.location.pathname
      const protocol = window.location.protocol
      const hostname = window.location.hostname
      const port = window.location.port
      const backUrl = `${protocol}//${hostname}${
        port ? `:${port}` : ``
      }${pathname}`

      if (ENVIRONMENT === 'staging') {
        window.location.replace(
          `https://staging.friendsclub.ru/my/?backUrl=${encodeURIComponent(
            backUrl
          )}`
        )
      } else {
        window.location.replace(
          `https://friendsclub.ru/my/?backUrl=${encodeURIComponent(backUrl)}`
        )
      }
    }

    dispatch(setUrlParams(urlParamsResult))
  }, [dispatch])

  return { urlSearchParams: urlSearchParamsLocal }
}
