import { logoFooterMobileWrapperPropsNewYearGame } from './logoFooterMobileWrapperPropsNewYearGame'
import { logoFooterMobileWrapperPropsTreasureIslandGame } from './logoFooterMobileWrapperPropsTreasureIslandGame'
import { logoFooterMobileWrapperPropsKDPayGame } from './logoFooterMobileWrapperPropsKDPayGame'

const logoFooterMobileWrapperPropsByGameId = {
  1: logoFooterMobileWrapperPropsNewYearGame,
  2: logoFooterMobileWrapperPropsTreasureIslandGame,
  3: logoFooterMobileWrapperPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const logoFooterMobileWrapperProps =
  logoFooterMobileWrapperPropsByGameId[gameId]
