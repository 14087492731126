import { useBgImageNewYearGame } from './useBgImageNewYearGame'
import { useBgImageTreasureIslandGame } from './useBgImageTreasureIslandGame'
import { useBgImageKDPayGame } from './useBgImageKDPayGame'

const useBgImageByGameId = {
  1: useBgImageNewYearGame,
  2: useBgImageTreasureIslandGame,
  3: useBgImageKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useBgImage = useBgImageByGameId[gameId]
