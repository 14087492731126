import theme from 'ui/settings/theme'

export const contentSectionBottomPropsNewYearGame = {
  mb: '20px',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '19px',
  letterSpacing: '0.5px',
  textAlign: 'center',
  color: theme.colors.white,
}
