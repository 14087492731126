import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { keysFetch } from '../store/slices/keysSlice'

export const useKeysManager = ({ isAutoLoad = true } = {}) => {
  const keys = useSelector(state => state.keys.data)
  const keysCount = useSelector(state => state.keys.data?.length)
  const isLoading = useSelector(state => state.keys.loading)
  const error = useSelector(state => state.keys.error)
  const urlSearchParams = useSelector(state => state.user.urlSearchParams)
  const dispatch = useDispatch()

  const fetchKeys = useCallback(() => dispatch(keysFetch()), [dispatch])

  useEffect(() => {
    if (
      isAutoLoad &&
      !!urlSearchParams?.session_id &&
      !!urlSearchParams?.user_id &&
      !isLoading
    ) {
      fetchKeys()
    }
  }, [
    fetchKeys,
    isAutoLoad,
    urlSearchParams?.session_id,
    urlSearchParams?.user_id,
  ])

  return { keys, keysCount, isLoading, error, fetchKeys }
}
