import theme from 'ui/settings/theme'

export const contentPropsTreasureIslandGame = {
  mb: '22px',
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '130%',
  letterSpacing: '0.5px',
  textAlign: 'center',
  color: theme.colors.brown[0],
}
