import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  calendarDetailsFetch,
  setCurrentlyOpeningGiftData,
  setGiftReceivingLoadingAndAnimationShowTruthy,
  stopGiftReceivingAnimation,
} from 'store/slices'

export const useCalendarManager = () => {
  const calendarDetails = useSelector(
    state => state.calendar.calendarDetailsData
  )
  const isCalendarDetailsLoading = useSelector(
    state => state.calendar.calendarDetailsLoading
  )
  const calendarDetailsError = useSelector(
    state => state.calendar.calendarDetailsError
  )

  const giftReceivingError = useSelector(
    state => state.calendar.giftReceivingError
  )

  const giftReceivingLoading = useSelector(
    state => state.calendar.giftReceivingLoading
  )

  const giftReceivingAnimationShow = useSelector(
    state => state.calendar.giftReceivingAnimationShow
  )

  const currentlyOpeningGiftData = useSelector(
    state => state.calendar.currentlyOpeningGiftData
  )

  const dispatch = useDispatch()

  const fetchCalendarDetails = useCallback(
    id => dispatch(calendarDetailsFetch(id)),
    [dispatch]
  )

  const stopGiftReceivingAnimationWrapper = () => {
    return dispatch(stopGiftReceivingAnimation())
  }

  const setGiftReceivingLoadingAndAnimationShowTruthyWrapper = () => {
    return dispatch(setGiftReceivingLoadingAndAnimationShowTruthy())
  }

  const setCurrentlyOpeningGiftDataWrapper = data => {
    return dispatch(setCurrentlyOpeningGiftData(data))
  }

  return {
    calendarDetails,
    currentlyOpeningGiftData,
    isCalendarDetailsLoading,
    calendarDetailsError,
    giftReceivingError,
    giftReceivingLoading,
    giftReceivingAnimationShow,
    fetchCalendarDetails,
    stopGiftReceivingAnimation: stopGiftReceivingAnimationWrapper,
    setGiftReceivingLoadingAndAnimationShowTruthy:
      setGiftReceivingLoadingAndAnimationShowTruthyWrapper,
    setCurrentlyOpeningGiftData: setCurrentlyOpeningGiftDataWrapper,
  }
}
