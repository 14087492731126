import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { calendarsFetch } from '../store/slices/calendarsSlice'

export const useCalendarsManager = () => {
  const calendars = useSelector(state => state.calendars.data)
  const isLoading = useSelector(state => state.calendars.loading)
  const error = useSelector(state => state.calendars.error)
  const urlSearchParams = useSelector(state => state.user.urlSearchParams)

  const dispatch = useDispatch()

  const fetchCalendars = useCallback(
    () => dispatch(calendarsFetch()),
    [dispatch]
  )

  useEffect(() => {
    if (
      !!urlSearchParams?.session_id &&
      !!urlSearchParams?.user_id &&
      !isLoading
    ) {
      fetchCalendars()
    }
  }, [fetchCalendars, urlSearchParams?.session_id, urlSearchParams?.user_id])

  return { calendars, isLoading, error, fetchCalendars }
}
