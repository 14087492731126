import { useDesktopBackgroundNewYearGame } from './useDesktopBackgroundNewYearGame'
import { useDesktopBackgroundTreasureIslandGame } from './useDesktopBackgroundTreasureIslandGame'
import { useDesktopBackgroundKDPayGame } from './useDesktopBackgroundKDPayGame'

const useDesktopBackgroundByGameId = {
  1: useDesktopBackgroundNewYearGame,
  2: useDesktopBackgroundTreasureIslandGame,
  3: useDesktopBackgroundKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useDesktopBackground = useDesktopBackgroundByGameId[gameId]
