import theme from 'ui/settings/theme'

export const ticketValueTextPropsNewYearGame = {
  mb: '16px',
  fontSize: '25px',
  fontWeight: '600',
  lineHeight: '34px',
  textAlign: 'center',
  color: theme.colors.black[0],
}
