import { useMemo } from 'react'

import { CALENDAR_ITEM_STATUS } from 'constants/calendarGifts'

export const useGiftImgKDPayGame = ({
  calendarItem,
  index,
  giftReceivingAnimationShow,
  currentlyOpeningGiftData,
}) => {
  const giftImg = useMemo(() => {
    if (calendarItem?.status === CALENDAR_ITEM_STATUS.OPEN) {
      return calendarItem?.gift?.logo_url
    }

    if (
      currentlyOpeningGiftData?.itemIndex === index &&
      giftReceivingAnimationShow
    ) {
      return `/img/kdPayGame/scratch-layer-animation.gif`
    }

    return `/img/kdPayGame/closed_gift.png`
  }, [
    calendarItem,
    index,
    giftReceivingAnimationShow,
    currentlyOpeningGiftData,
  ])

  return giftImg
}
