import { popupInfoPropsNewYearGame } from './popupInfoPropsNewYearGame'
import { popupInfoPropsTreasureIslandGame } from './popupInfoPropsTreasureIslandGame'
import { popupInfoPropsKDPayGame } from './popupInfoPropsKDPayGame'

const popupInfoPropsByGameId = {
  1: popupInfoPropsNewYearGame,
  2: popupInfoPropsTreasureIslandGame,
  3: popupInfoPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const popupInfoProps = popupInfoPropsByGameId[gameId]
