import { fortuneValueTextPropsNewYearGame } from './fortuneValueTextPropsNewYearGame'
import { fortuneValueTextPropsTreasureIslandGame } from './fortuneValueTextPropsTreasureIslandGame'
import { fortuneValueTextPropsKDPayGame } from './fortuneValueTextPropsKDPayGame'

const fortuneValueTextPropsByGameId = {
  1: fortuneValueTextPropsNewYearGame,
  2: fortuneValueTextPropsTreasureIslandGame,
  3: fortuneValueTextPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const fortuneValueTextProps = fortuneValueTextPropsByGameId[gameId]
