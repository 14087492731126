import styled from 'styled-components'

export const TicketListWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 20px;
    scroll-margin-left: 20px;
  }

  &::-webkit-scrollbar-track {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: #fff;
  }
`
