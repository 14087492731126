import styled, { css } from 'styled-components'
import {
  compose,
  effects,
  color,
  borders,
  layout,
  space,
  sizing,
  typography,
} from '@xstyled/styled-components'

import { ButtonVariants } from './ButtonVariants'

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  ${compose(color, borders, layout, space, sizing, typography, effects)}
  ${ButtonVariants}
  
  ${props =>
    props.isLoading &&
    css`
      color: transparent !important;
      pointer-events: none;

      &:after {
        content: '';
        position: absolute;
        left: calc(50% - (1em * 0.5));
        top: calc(50% - (1em * 0.5));
        display: block;
        width: 1em;
        height: 1em;
        border-radius: 9999px;
        border-right-color: transparent;
        border-top-color: transparent;
        animation: spinAround 0.5s infinite linear;
      }

      @keyframes spinAround {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(359deg);
        }
      }
    `}
`

Button.defaultProps = {
  position: 'relative',
  variant: 'default',
  px: '20px',
  py: '16px',
  borderRadius: '999px',
  // borderWidth: '2px',
  // borderStyle: 'solid',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '28px',
}
