export const ENDPOINTS = {
  CODES: '/codes',
  COUPONCOUNT: '/couponcount',
  COUPONLIST: '/couponlist',
  COUPONREALIZE: '/couponrealize',
  COUPONREALIZE_FINAL: '/couponrealize-final',
  FINALLIST: '/finallist',
  CALENDARS: '/game_coupon/calendar/list',
  KEYS: '/game_coupon/keys',
  TICKETS: '/game_coupon/gift/tickets',
  GIFT_RECEIVING: '/game_coupon/gift/receiving',
  CALENDAR_DETAILS: '/game_coupon/calendar/details',
}
