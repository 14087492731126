import { TicketListContentNewYearGame } from './TicketListContentNewYearGame'
import { TicketListContentTreasureIslandGame } from './TicketListContentTreasureIslandGame'
import { TicketListContentKDPayGame } from './TicketListContentKDPayGame'

const TicketListContentByGameId = {
  1: TicketListContentNewYearGame,
  2: TicketListContentTreasureIslandGame,
  3: TicketListContentKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TicketListContent = TicketListContentByGameId[gameId]
