import { Fragment } from 'react'

import {
  TICKETS_LOADING_ERROR_MSG_BOTTOM,
  TICKETS_LOADING_ERROR_MSG_TOP,
} from '../../constants/messages'
import { Box, Button, Preloader, Text } from '../atoms'

const ErrorAndLoaderWrapper = ({ children, ...props }) => (
  <Box
    w="100%"
    minHeight="132px"
    padding="16px"
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    className="tickets-error-and-loader-wrapper"
    {...props}
  >
    {children}
  </Box>
)

const InfoError = ({ onRetry }) => (
  <Fragment>
    <Text
      fontWeight="700"
      fontSize="20px"
      lineHeight="26px"
      textAlign="center"
      letterSpacing="0.5px"
      color="#fff"
    >
      {TICKETS_LOADING_ERROR_MSG_TOP}
    </Text>
    <Text
      mb="40px"
      fontWeight="700"
      fontSize="20px"
      lineHeight="26px"
      textAlign="center"
      letterSpacing="0.5px"
      color="#fff"
    >
      {TICKETS_LOADING_ERROR_MSG_BOTTOM}
    </Text>

    <Button variant="outlined" w="258px" onClick={onRetry}>
      Обновить
    </Button>
  </Fragment>
)

export const TicketsError = ({
  isLoading = false,
  hasError = false,
  onRetry = () => {},
  ...props
}) => {
  return (
    <ErrorAndLoaderWrapper {...props}>
      {isLoading && <Preloader />}
      {!isLoading && hasError && <InfoError onRetry={onRetry} />}
    </ErrorAndLoaderWrapper>
  )
}
