import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { PATHS } from './routing/paths'
import { useUserManager } from './hooks/useUserManager'

import {
  Home,
  Rules,
  Calendars,
  Tickets,
  Sponsors,
  RaffleWinners,
  Calendar,
} from './pages'

const App = () => {
  useUserManager()

  return (
    <Router>
      <Routes>
        <Route path={PATHS.HOME} exact element={<Home />} />
        <Route path={PATHS.RULES} element={<Rules />} />
        <Route path={PATHS.CALENDARS} element={<Calendars />} />
        <Route path={PATHS.CALENDAR} element={<Calendar />} />
        <Route path={PATHS.TICKETS} element={<Tickets />} />
        <Route path={PATHS.SPONSORS} element={<Sponsors />} />
        <Route path={PATHS.WINNERS} element={<RaffleWinners />} />
      </Routes>
    </Router>
  )
}

export default App
