import { keyImgPropsNewYearGame } from './keyImgPropsNewYearGame'
import { keyImgPropsTreasureIslandGame } from './keyImgPropsTreasureIslandGame'
import { keyImgPropsKDPayGame } from './keyImgPropsKDPayGame'

const keyImgPropsByGameId = {
  1: keyImgPropsNewYearGame,
  2: keyImgPropsTreasureIslandGame,
  3: keyImgPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const keyImgProps = keyImgPropsByGameId[gameId]
