import { useLayoutEffect } from 'react'

export const useWindowResize = callback => {
  useLayoutEffect(() => {
    callback?.()
  }, [])

  useLayoutEffect(() => {
    window.addEventListener('resize', callback)
    return () => window.removeEventListener('resize', callback)
  }, [callback])
}
