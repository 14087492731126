import styled, { css } from 'styled-components'

export const BoardSceneWrapperKDPayGame = styled.div`
  ${({ isWideViewport }) =>
    isWideViewport
      ? css`
          position: relative;
        `
      : css`
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          bottom: 0;
        `};
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 460px) {
    bottom: 1%;
  }

  @media (max-width: 340px) {
    bottom: 2%;
  }

  @media (max-width: 280px) {
    bottom: 3%;
  }
`
