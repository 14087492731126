import styled from 'styled-components'

export const KeysCarouselControlTreasureIslandGame = styled.button`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 30px;
  height: 68px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.brown[4]};
  fill: #fff;
  user-select: none;

  &.carousel-prev {
    margin-right: 12px;
  }

  &.carousel-next {
    margin-left: 12px;
  }

  @media (min-width: 768px) {
    height: 82px;

    &.carousel-prev {
      margin-right: 28px;
    }

    &.carousel-next {
      margin-left: 28px;
    }
  }

  > img {
    opacity: 0.5;
  }

  &:not(.swiper-button-disabled) > img {
    opacity: 1;
  }
`
