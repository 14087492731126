import { fortuneCaptionTextPropsNewYearGame } from './fortuneCaptionTextPropsNewYearGame'
import { fortuneCaptionTextPropsTreasureIslandGame } from './fortuneCaptionTextPropsTreasureIslandGame'
import { fortuneCaptionTextPropsKDPayGame } from './fortuneCaptionTextPropsKDPayGame'

const fortuneCaptionTextPropsPropsByGameId = {
  1: fortuneCaptionTextPropsNewYearGame,
  2: fortuneCaptionTextPropsTreasureIslandGame,
  3: fortuneCaptionTextPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const fortuneCaptionTextProps =
  fortuneCaptionTextPropsPropsByGameId[gameId]
