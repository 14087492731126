export const keysInfoWrapperPropsMobileTreasureIslandGame = {
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: '0',
  w: '100%',
  padding: '24px 16px 24px 16px',
  border: '2px solid #ffffff',
  borderRadius: '16px',
  backgroundColor: '#21A9FD',
  mb: { lg: '40px' },
  className: 'calendars-info-wrapper',
}
