import styled from 'styled-components'

export const ActionDescriptionWrapperTreasureIslandGame = styled.div`
  width: 257px;
  margin: 0 auto 20px auto;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.brown[0]};
`
