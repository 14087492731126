import { usePreview } from 'react-dnd-preview'

import { BoardKeyPreviewWrapper } from './BoardKeyPreviewWrapper'

const BASE_KEY_PREVIEW_WIDTH_PX = 100
const BASE_KEY_CAROUSEL_WIDTH_PX = 65
const KEY_CAROUSEL_WIDTH_PERCENT = 13

const widthPercent =
  KEY_CAROUSEL_WIDTH_PERCENT *
  (BASE_KEY_PREVIEW_WIDTH_PX / BASE_KEY_CAROUSEL_WIDTH_PX)

export const BoardKeyPreview = () => {
  const { display, style } = usePreview()

  if (!display) {
    return null
  }

  return <BoardKeyPreviewWrapper $width={widthPercent} style={style} />
}
