import { PopupInfoLogoNewYearGame } from './PopupInfoLogoNewYearGame'
import { PopupInfoLogoTreasureIslandGame } from './PopupInfoLogoTreasureIslandGame'
import { PopupInfoLogoKDPayGame } from './PopupInfoLogoKDPayGame'

const PopupInfoLogoByGameId = {
  1: PopupInfoLogoNewYearGame,
  2: PopupInfoLogoTreasureIslandGame,
  3: PopupInfoLogoKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupInfoLogo = PopupInfoLogoByGameId[gameId]
