import { PATHS } from '../routing/paths'

export const getNavLinkBasedOnLocation = location => {
  switch (location) {
    case PATHS.CALENDAR:
      return PATHS.CALENDARS
    default:
      return PATHS.HOME
  }
}
