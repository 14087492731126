import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import keysSlice from './slices/keysSlice'
import calendarsSlice from './slices/calendarsSlice'
import calendarSlice from './slices/calendarSlice'
import userSlice from './slices/userSlice'
import ticketsSlice from './slices/ticketsSlice'
import popupsSlice from './slices/popupsSlice'
import { DEFAULT_CLIENT_ID } from 'constants/clientID'

const rootReducer = combineReducers({
  user: userSlice.reducer,
  calendars: calendarsSlice.reducer,
  calendar: calendarSlice.reducer,
  keys: keysSlice.reducer,
  tickets: ticketsSlice.reducer,
  popups: popupsSlice.reducer,
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [userSlice.name],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// Если "старый" пользователь ранее заходил в игру и у него ранее сохраниись
// данные (session_id, user_id и т.д.) в localStorage, но в этих данных нет
// параметра client_id, то перед хидрацией из local storage "redux-persist"
// в redux стор, мы подменяем данные для поля client_id на значение по умолчанию

// Так сделано, чтобы АПИ запросы были сделаны уже с правильным значением client_id,
// которые происходят сразу после гидрации данных из local storage "redux-persist" в
// redux стор

// Этот код полностью рабочий
// Заметка: необходимо раскомментировать, когда другие значения параметра client_id
// кроме 1 (например равные 12) будут работать корректно.

// let persistRoot = JSON.parse(localStorage.getItem('persist:root'))
// if (persistRoot) {
//   if (persistRoot.user) {
//     persistRoot.user = JSON.parse(persistRoot.user)
//     if (persistRoot.user.urlSearchParams) {
//       if (!persistRoot.user.urlSearchParams.client_id) {
//         persistRoot.user.urlSearchParams = {
//           ...persistRoot.user.urlSearchParams,
//           client_id: DEFAULT_CLIENT_ID,
//         }
//       }
//     }
//     persistRoot.user = JSON.stringify(persistRoot.user)
//   }
// }
// storage.setItem('persist:root', JSON.stringify(persistRoot))

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)
