import { buttonPropsNewYearGame } from './buttonPropsNewYearGame'
import { buttonPropsTreasureIslandGame } from './buttonPropsTreasureIslandGame'
import { buttonPropsKDPayGame } from './buttonPropsKDPayGame'

const buttonPropsByGameId = {
  1: buttonPropsNewYearGame,
  2: buttonPropsTreasureIslandGame,
  3: buttonPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const buttonProps = buttonPropsByGameId[gameId]
