import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { PATHS } from '../../routing/paths'
import { Box, Button, Flex, TextTitle } from '../../ui/atoms'
import { Notice } from '../../ui/organisms'
import { LayoutMobile } from '../../ui/layout'
import { config } from 'config'

const TextTitleHome = styled(TextTitle)`
  text-shadow: ${({ theme }) => `2px 2px ${theme.colors.textTertiaryColor}`};
`

const TextTitleSmallerHome = styled(TextTitle)`
  text-shadow: ${({ theme }) => `2px 2px ${theme.colors.textTertiaryColor}`};
  text-transform: none;
`

export const HomeMobile = () => {
  return (
    <LayoutMobile
      {...config.backgrounds.HOME_MOBILE_BACKGROUND}
      isDisplayLogoFooter={true}
    >
      <LayoutMobile.Content>
        <Flex
          display="flex"
          width="100%"
          flexDirection="column"
          alignItems="center"
        >
          <Notice mb={{ _: '50px', md: '28px' }} />

          <Box
            alt={config.texts.homePage.headingAlt}
            maxWidth={{ _: '100%', md: '486px' }}
            mb={{ _: '50px', md: '58px' }}
            style={{ textAlign: 'center' }}
          >
            {config.texts.homePage.headingMobile.map((textLine, index) => {
              return (
                <TextTitleHome fontSize="28px" key={index}>
                  {textLine}
                </TextTitleHome>
              )
            })}
          </Box>

          {/* <Box
            maxWidth={{ _: '100%', md: '486px' }}
            mb="16px"
            style={{ textAlign: 'center' }}
          >
            {config.texts.homePage.promoactionFinished.map(
              (textLine, index) => {
                return (
                  <TextTitleHome fontSize="28px" key={index}>
                    {textLine}
                  </TextTitleHome>
                )
              }
            )}
          </Box> */}

          {/* <Box
            maxWidth={{ _: '100%', md: '486px' }}
            mb="33px"
            style={{ textAlign: 'center' }}
          >
            <TextTitleSmallerHome fontSize="24px">
              {config.texts.homePage.seeYouInNewGames}
            </TextTitleSmallerHome>
          </Box> */}

          <Box maxWidth={{ md: '486px' }} w="100%">
            <Link to={PATHS.CALENDARS}>
              <Button
                as="span"
                variant="primary"
                w="100%"
                px={{ _: '16px', xs: '20px' }}
                py={{ _: '12px', xs: '16px' }}
                mb="16px"
                fontSize={{ _: '16px', xs: '20px' }}
                boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
              >
                {config.texts.homePage.toCalendars}
              </Button>
            </Link>

            <Link to={PATHS.TICKETS}>
              <Button
                as="span"
                variant="default"
                w="100%"
                px={{ _: '16px', xs: '20px' }}
                py={{ _: '12px', xs: '16px' }}
                fontSize={{ _: '16px', xs: '20px' }}
                boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
              >
                Мои билеты
              </Button>
            </Link>
          </Box>
        </Flex>
      </LayoutMobile.Content>
    </LayoutMobile>
  )
}
