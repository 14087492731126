export const converToRussianDateFormat = isoDate => {
  if (!isoDate) {
    return null
  }

  const dateRegExp = /(\d{4})-(\d{2})-(\d{2})/i
  const matchResult = isoDate.match(dateRegExp)

  if (matchResult) {
    const year = matchResult[1]
    const month = matchResult[2]
    const day = matchResult[3]

    return `${day}.${month}.${year}`
  }

  return null
}
