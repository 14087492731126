import styled from 'styled-components'
import { Box, Button, Text } from 'ui/atoms'
import { PopupCouponGiftBase } from 'ui/molecules'
import theme from 'ui/settings/theme'
import { ActionDescriptionWrapper } from './ActionDescriptionWrapper'
import { buttonProps } from './buttonProps'
import { yourGiftTextProps } from './yourGiftTextProps'
import { congratulationsTextProps } from './congratulationsTextProps'
import { couponValueTextProps } from './couponValueTextProps'
import { config } from 'config'

export const PopupCouponGift = ({
  couponValue,
  couponDescription,
  isOpened,
  onClose,
}) => (
  <PopupCouponGiftBase isOpened={isOpened} onClose={onClose}>
    <Box {...yourGiftTextProps}>
      {config.texts.popups.couponGift.heading.map((textLine, index) => {
        return <div key={index}>{textLine}</div>
      })}
    </Box>
    {config.texts.popups.couponGift.subheading &&
      config.texts.popups.couponGift.subheading.length > 0 && (
        <Box {...congratulationsTextProps}>
          {config.texts.popups.couponGift.subheading.map((textLine, index) => {
            return <div key={index}>{textLine}</div>
          })}
        </Box>
      )}
    <Box
      w="257px"
      p="16px 8px 16px 8px"
      borderRadius="12px"
      m="0 auto 20px auto"
      backgroundColor="#ffffff"
    >
      <Text {...couponValueTextProps}>{couponValue}</Text>
    </Box>
    <ActionDescriptionWrapper>{couponDescription}</ActionDescriptionWrapper>
    <Button {...buttonProps} onClick={onClose}>
      Продолжить
    </Button>
  </PopupCouponGiftBase>
)
