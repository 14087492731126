export const BASE_ERROR_MESSAGE = 'Упс! Произошла ошибка. Попробуй позднее.'

export const BASE_ERROR_MESSAGE_TOP = 'Упс!'

export const BASE_ERROR_MESSAGE_BOTTOM = 'Произошла ошибка. Попробуй позднее.'

export const GIFT_OPEN_FAIL_MESSAGE_BOTTOM =
  'Произошла ошибка при открытии подарка'

export const TICKETS_LOADING_ERROR_MSG_TOP = 'Упс! Произошла ошибка'

export const TICKETS_LOADING_ERROR_MSG_BOTTOM = 'загрузки твоих билетов'
