import { headingPropsNewYearGame } from './headingPropsNewYearGame'
import { headingPropsTreasureIslandGame } from './headingPropsTreasureIslandGame'
import { headingPropsKDPayGame } from './headingPropsKDPayGame'

const headingPropsByGameId = {
  1: headingPropsNewYearGame,
  2: headingPropsTreasureIslandGame,
  3: headingPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const headingProps = headingPropsByGameId[gameId]
