import { keysInfoWrapperPropsDesktopNewYearGame } from './keysInfoWrapperPropsDesktopNewYearGame'
import { keysInfoWrapperPropsDesktopTreasureIslandGame } from './keysInfoWrapperPropsDesktopTreasureIslandGame'
import { keysInfoWrapperPropsDesktopKDPayGame } from './keysInfoWrapperPropsDesktopKDPayGame'

const keysInfoWrapperPropsDesktopByGameId = {
  1: keysInfoWrapperPropsDesktopNewYearGame,
  2: keysInfoWrapperPropsDesktopTreasureIslandGame,
  3: keysInfoWrapperPropsDesktopKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const keysInfoWrapperPropsDesktop =
  keysInfoWrapperPropsDesktopByGameId[gameId]
