import styled from 'styled-components'

import { TextTitle } from 'ui/atoms'

export const RaffleWinnerItemTextKDPayGame = styled(TextTitle)`
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
  text-shadow: ${({ theme }) => `2px 2px ${theme.colors.black[3]}`};
  text-transform: none;
`
