import useMatchMedia from 'react-use-match-media'
import { useMemo } from 'react'
import cloneDeep from 'lodash.clonedeep'

import { config } from 'config'

export const useMobileBackgroundKDPayGame = ({ calendarType }) => {
  const isTabletViewport = useMatchMedia('(min-width: 426px)')
  const isLaptopViewport = useMatchMedia('(min-width: 768px)')

  const background = useMemo(() => {
    let background

    if (isLaptopViewport) {
      background =
        config.backgrounds[`CALENDAR_TABLET_BACKGROUND_${calendarType}`]
    } else {
      background = config.backgrounds[`CALENDAR_MOBILE_BACKGROUND`]
    }

    return background
  }, [isTabletViewport, isLaptopViewport, calendarType])

  return background
}
