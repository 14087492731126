import { useKeysManager } from 'hooks/useKeysManager'
import { Box, Button, Flex, Text } from 'ui/atoms'
import { KeysCarousel } from '../KeysCarousel'
import { useCalendarManager } from 'hooks/useCalendarManager'
import { keysBoxWrapperProps } from './keysBoxWrapperProps'
import { config } from 'config'
import { KeysBoxWrapper } from './KeysBoxWrapper'

export const KeysBox = ({ calendarUpdate, ...props }) => {
  const {
    keys,
    keysCount = 0,
    error: keysError,
  } = useKeysManager({ isAutoLoad: false })
  const {
    giftReceivingAnimationShow,
    calendarDetailsError,
    giftReceivingError,
  } = useCalendarManager()

  return (
    <KeysBoxWrapper
      {...keysBoxWrapperProps}
      {...props}
      $disabled={giftReceivingAnimationShow}
    >
      <Flex
        as="header"
        justifyContent="space-between"
        alignItems="center"
        px={{ _: '20px', lgWide: '24px' }}
        py={{ _: '16px', lgWide: '22px' }}
        borderBottom={{ lgWide: '2px solid rgba(255, 255, 255, 0.4)' }}
      >
        <Text
          fontSize={{ _: '18px', lgWide: '24px' }}
          fontWeight="600"
          lineHeight={{ _: '24px', lgWide: '32px' }}
          color="#fff"
          userSelect="none"
        >
          {config.texts.keysBox.keys}
        </Text>
        <Text
          fontSize={{ _: '16px', lgWide: '18px' }}
          fontWeight="400"
          lineHeight={{ _: '16px', lgWide: '18px' }}
          color="#fff"
          userSelect="none"
        >
          Всего {keysCount}
        </Text>
      </Flex>

      <Box
        padding={{ _: '0 20px 28px', lgWide: '26px 22px' }}
        minWidth={{ lgWide: '400px' }}
      >
        {keysError || calendarDetailsError || giftReceivingError ? (
          <Flex justifyContent="center" alignItems="center" h={{ sm: '82px' }}>
            <Button
              w="195px"
              h="44px"
              maxWidth="195px"
              fontSize="16px"
              variant="outlined"
              onClick={calendarUpdate}
            >
              Обновить
            </Button>
          </Flex>
        ) : (
          <KeysCarousel keys={keys} keysCount={keysCount} />
        )}
      </Box>
    </KeysBoxWrapper>
  )
}
