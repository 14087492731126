import { raffleWinnerItemImgPropsNewYearGame } from './raffleWinnerItemImgPropsNewYearGame'
import { raffleWinnerItemImgPropsTreasureIslandGame } from './raffleWinnerItemImgPropsTreasureIslandGame'
import { raffleWinnerItemImgPropsKDPayGame } from './raffleWinnerItemImgPropsKDPayGame'

const raffleWinnerItemImgPropsByGameId = {
  1: raffleWinnerItemImgPropsNewYearGame,
  2: raffleWinnerItemImgPropsTreasureIslandGame,
  3: raffleWinnerItemImgPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const raffleWinnerItemImgProps = raffleWinnerItemImgPropsByGameId[gameId]
