import styled from 'styled-components'
import {
  backgrounds,
  borders,
  typography,
  space,
  color,
  cursor,
  margin,
  display,
  userSelect,
} from '@xstyled/styled-components'

export const Text = styled.p(
  backgrounds,
  borders,
  typography,
  space,
  color,
  cursor,
  margin,
  display,
  userSelect
)
