import { raffleDateTextPropsNewYearGame } from './raffleDateTextPropsNewYearGame'
import { raffleDateTextPropsTreasureIslandGame } from './raffleDateTextPropsTreasureIslandGame'
import { raffleDateTextPropsKDPayGame } from './raffleDateTextPropsKDPayGame'

const raffleDateTextPropsByGameId = {
  1: raffleDateTextPropsNewYearGame,
  2: raffleDateTextPropsTreasureIslandGame,
  3: raffleDateTextPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const raffleDateTextProps = raffleDateTextPropsByGameId[gameId]
