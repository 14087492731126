import { contentTextPropsNewYearGame } from './contentTextPropsNewYearGame'
import { contentTextPropsTreasureIslandGame } from './contentTextPropsTreasureIslandGame'
import { contentTextPropsKDPayGame } from './contentTextPropsKDPayGame'

const contentTextPropsByGameId = {
  1: contentTextPropsNewYearGame,
  2: contentTextPropsTreasureIslandGame,
  3: contentTextPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const contentTextProps = contentTextPropsByGameId[gameId]
