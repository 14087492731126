export const HOME_DESKTOP_BACKGROUND = {
  backgroundColor: '#A7D0EE',
  backgroundImage:
    'url(/img/home_desktop_logo.png), url(/img/home_desktop_bg.png)',
  backgroundPosition: 'right -90px top 30px, left 0 bottom 0',
  backgroundRepeat: 'no-repeat, no-repeat',
  backgroundSize: 'auto 100%, 100vw auto',
}

export const HOME_MOBILE_BACKGROUND = {
  backgroundColor: '#A7D0EE',
  backgroundImage: 'url(/img/home_mobile_bg.png)',
  backgroundPosition: '0% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '100vw auto',
  },
}

export const RULES_BACKGROUND = {
  backgroundImage: 'linear-gradient(0deg, #A7D0EE 0%, #8DC9F5 53.02%)',
  backgroundRepeat: 'no-repeat',
}

export const CALENDARS_MOBILE_BACKGROUND = {
  backgroundColor: '#A7D0EE',
}

export const CALENDARS_DESKTOP_BACKGROUND = {
  backgroundColor: '#A7D0EE',
  backgroundImage: 'url(/img/newYearGame/snow_and_christmas_trees.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: 'cover',
  },
}

export const CALENDAR_TABLET_BACKGROUND = {
  backgroundColor: '#A7D0EE',
  backgroundImage: 'url(/img/calendar_board_bg_tablet.png)',
  backgroundPosition: {
    _: 'left 0 bottom 100px',
    sm: 'left 0 bottom 0',
  },
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% auto',
}

export const CALENDAR_MOBILE_BACKGROUND = {
  backgroundColor: '#A7D0EE',
  backgroundImage: 'url(/img/calendar_board_bg_mobile.png)',
  backgroundPosition: {
    _: 'left 0 bottom 100px',
    sm: 'left 0 bottom 0',
  },
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% auto',
}

export const CALENDAR_DESKTOP_BACKGROUND = {
  backgroundColor: '#A7D0EE',
}

export const TICKETS_MOBILE_BACKGROUND = {
  backgroundColor: '#A7D0EE',
  backgroundImage: 'url(/img/bear_and_horse_bg.png)',
  backgroundPosition: 'left 0 bottom 0',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '100vw auto',
  },
}

export const TICKETS_DESKTOP_BACKGROUND = {
  backgroundColor: '#A7D0EE',
  backgroundImage: 'url(/img/newYearGame/snow_and_christmas_trees.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: 'cover',
  },
}

export const SPONSORS_MOBILE_BACKGROUND = {
  backgroundColor: '#A7D0EE',
}

export const RAFFLE_WINNERS_MOBILE_BACKGROUND = {
  backgroundColor: '#A7D0EE',
}
