import { ticketValuePropsNewYearGame } from './ticketValuePropsNewYearGame'
import { ticketValuePropsTreasureIslandGame } from './ticketValuePropsTreasureIslandGame'
import { ticketValuePropsKDPayGame } from './ticketValuePropsKDPayGame'

const ticketValuePropsByGameId = {
  1: ticketValuePropsNewYearGame,
  2: ticketValuePropsTreasureIslandGame,
  3: ticketValuePropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketValueProps = ticketValuePropsByGameId[gameId]
