import * as ticketsMobileConstantsNewYearGame from './ticketsMobileConstantsNewYearGame'
import * as ticketsMobileConstantsTreasureIslandGame from './ticketsMobileConstantsTreasureIslandGame'
import * as ticketsMobileConstantsKDPayGame from './ticketsMobileConstantsKDPayGame'

const ticketsMobileContantsByGameId = {
  1: ticketsMobileConstantsNewYearGame,
  2: ticketsMobileConstantsTreasureIslandGame,
  3: ticketsMobileConstantsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketsMobileContants = ticketsMobileContantsByGameId[gameId]
