import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFirstVisit, setIsRulesPopupWasShown } from 'store/slices'

export const useUserFirstVisit = ({
  rulesPopupHandler,
  keyDescriptionHandler,
} = {}) => {
  const dispatch = useDispatch()
  const firstVisit = useSelector(state => state.user.firstVisit)

  useEffect(() => {
    if (
      firstVisit === undefined ||
      firstVisit?.id !== process.env.REACT_APP_GAME_ID
    ) {
      rulesPopupHandler && rulesPopupHandler()
      keyDescriptionHandler && keyDescriptionHandler()
      dispatch(
        setFirstVisit({
          id: process.env.REACT_APP_GAME_ID,
          isRulesPopupWasShown: !!rulesPopupHandler,
          isKeyDescriptionWasShown: !!keyDescriptionHandler,
        })
      )
    } else {
      if (
        firstVisit?.id === process.env.REACT_APP_GAME_ID &&
        !firstVisit?.isRulesPopupWasShown
      ) {
        rulesPopupHandler && rulesPopupHandler()
        dispatch(setIsRulesPopupWasShown(true))
      }

      if (
        firstVisit?.id === process.env.REACT_APP_GAME_ID &&
        !firstVisit?.isKeyDescriptionWasShown
      ) {
        keyDescriptionHandler && keyDescriptionHandler()
      }
    }
  }, [])

  return {
    isRulesPopupWasShown: firstVisit?.isRulesPopupWasShown,
    isKeyDescriptionWasShown: firstVisit?.isKeyDescriptionWasShown,
  }
}
