import theme from 'ui/settings/theme'

export const contentTextPropsKDPayGame = {
  mb: '22px',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '19px',
  textAlign: 'center',
  color: theme.colors.white,
}
