import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { BASE_ERROR_MESSAGE } from '../../constants/messages'
import api from '../../api'
import { DEFAULT_CLIENT_ID } from 'constants/clientID'

const initialState = {
  giftReceivingData: null,
  giftReceivingAnimationShow: false,
  giftReceivingLoading: false,
  currentlyOpeningGiftData: {},
  giftReceivingError: null,
  calendarDetailsData: null,
  calendarDetailsLoading: false,
  calendarDetailsError: null,
}

export const giftReceiving = createAsyncThunk(
  'calendar/giftReceiving',
  async (data, { rejectWithValue, getState }) => {
    const state = getState()
    const { urlSearchParams } = state.user
    const params = {
      ...urlSearchParams,
      client_id: DEFAULT_CLIENT_ID,
    }

    try {
      const response = await api.calendar.giftReceiving(data, params)

      return response ? response.data : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const calendarDetailsFetch = createAsyncThunk(
  'calendar/calendarDetails',
  async (id, { rejectWithValue, getState }) => {
    const state = getState()
    const { urlSearchParams } = state.user
    const params = {
      ...urlSearchParams,
      client_id: DEFAULT_CLIENT_ID,
      id,
    }

    try {
      const response = await api.calendar.calendarDetails(params)

      return response ? response.data : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const clendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    clearGiftReceivingError: state => {
      state.giftReceivingError = null
    },
    setSingleGiftData: (state, { payload }) => {
      const newCalendarItem = payload
      if (!!state.calendarDetailsData && !!state.calendarDetailsData.items) {
        state.calendarDetailsData.items = state.calendarDetailsData.items.map(
          calendarItem => {
            if (calendarItem.index === newCalendarItem.index) {
              return newCalendarItem
            }
            return calendarItem
          }
        )
      }
    },
    stopGiftReceivingAnimation: state => {
      state.giftReceivingAnimationShow = false
    },
    setCurrentlyOpeningGiftData: (state, { payload }) => {
      state.currentlyOpeningGiftData = payload
    },
    setGiftReceivingLoadingAndAnimationShowTruthy: state => {
      state.giftReceivingLoading = true
      state.giftReceivingAnimationShow = true
    },
  },
  extraReducers: {
    [giftReceiving.pending]: state => {
      state.giftReceivingLoading = true
      state.giftReceivingAnimationShow = true
    },
    [giftReceiving.fulfilled]: (state, { payload }) => {
      state.giftReceivingData = payload
      state.giftReceivingLoading = false
      state.giftReceivingAnimationShow = false
      state.giftReceivingError = null
      state.currentlyOpeningGiftData = {}
    },
    [giftReceiving.rejected]: (state, { payload }) => {
      state.giftReceivingLoading = false
      state.giftReceivingAnimationShow = false
      state.giftReceivingError = payload
      state.currentlyOpeningGiftData = {}
    },
    [calendarDetailsFetch.pending]: state => {
      state.calendarDetailsLoading = true
    },
    [calendarDetailsFetch.fulfilled]: (state, { payload }) => {
      state.calendarDetailsData = payload
      state.calendarDetailsLoading = false
      state.calendarDetailsError = null
    },
    [calendarDetailsFetch.rejected]: (state, { payload }) => {
      state.calendarDetailsLoading = false
      state.calendarDetailsError = payload
    },
  },
})

export const {
  setSingleGiftData,
  clearGiftReceivingError,
  stopGiftReceivingAnimation,
  setCurrentlyOpeningGiftData,
  setGiftReceivingLoadingAndAnimationShowTruthy,
} = clendarSlice.actions

export const { reducer: calendarReducer } = clendarSlice

export default clendarSlice
