export const CALENDAR_GIFTS_POSITION = {
  1: [
    {
      id: 1,
      position: {
        mobile: { bottom: '59.2%', left: '18.15%' },
        desktop: { bottom: '52.2%', left: '19.3%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { bottom: '59.2%', left: '40.35%' },
        desktop: { bottom: '52.2%', left: '26.95%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { bottom: '59.2%', left: '62.35%' },
        desktop: { bottom: '52.2%', left: '34.5%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { bottom: '44.3%', left: '18.15%' },
        desktop: { bottom: '38.6%', left: '19.3%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { bottom: '44.3%', left: '40.35%' },
        desktop: { bottom: '38.6%', left: '26.95%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { bottom: '44.3%', left: '62.35%' },
        desktop: { bottom: '38.6%', left: '34.5%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { bottom: '29.4%', left: '18.15%' },
        desktop: { bottom: '25%', left: '19.3%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { bottom: '29.4%', left: '40.35%' },
        desktop: { bottom: '25%', left: '26.95%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { bottom: '29.4%', left: '62.35%' },
        desktop: { bottom: '25%', left: '34.5%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { bottom: '14.7%', left: '18.15%' },
        desktop: { bottom: '11.4%', left: '19.3%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { bottom: '14.7%', left: '40.35%' },
        desktop: { bottom: '11.4%', left: '26.95%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { bottom: '14.7%', left: '62.35%' },
        desktop: { bottom: '11.4%', left: '34.5%' },
      },
    },
  ],

  2: [
    {
      id: 1,
      position: {
        mobile: { bottom: '60.6%', left: '23.55%' },
        desktop: { bottom: '52.2%', left: '18.2%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { bottom: '60.6%', left: '41.55%' },
        desktop: { bottom: '52.2%', left: '25.75%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { bottom: '60.6%', left: '59.35%' },
        desktop: { bottom: '52.2%', left: '33.3%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { bottom: '45.3%', left: '23.35%' },
        desktop: { bottom: '38.6%', left: '18.2%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { bottom: '45.3%', left: '41.55%' },
        desktop: { bottom: '38.6%', left: '25.75%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { bottom: '45.3%', left: '59.35%' },
        desktop: { bottom: '38.6%', left: '33.3%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { bottom: '30.1%', left: '23.55%' },
        desktop: { bottom: '25%', left: '18.2%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { bottom: '30.1%', left: '41.55%' },
        desktop: { bottom: '25%', left: '25.75%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { bottom: '30.1%', left: '59.35%' },
        desktop: { bottom: '25%', left: '33.3%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { bottom: '14.9%', left: '23.55%' },
        desktop: { bottom: '11.4%', left: '18.2%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { bottom: '14.9%', left: '41.55%' },
        desktop: { bottom: '11.4%', left: '25.75%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { bottom: '14.9%', left: '59.35%' },
        desktop: { bottom: '11.4%', left: '33.3%' },
      },
    },
  ],

  3: [
    {
      id: 1,
      position: {
        mobile: { bottom: '64.6%', left: '19.4%' },
        desktop: { bottom: '52.2%', left: '18.95%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { bottom: '64.6%', left: '40.2%' },
        desktop: { bottom: '52.2%', left: '26.65%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { bottom: '64.6%', left: '61%' },
        desktop: { bottom: '52.2%', left: '34.35%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { bottom: '49%', left: '19.4%' },
        desktop: { bottom: '38.6%', left: '18.95%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { bottom: '49%', left: '40.2%' },
        desktop: { bottom: '38.6%', left: '26.65%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { bottom: '49%', left: '61%' },
        desktop: { bottom: '38.6%', left: '34.35%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { bottom: '33.2%', left: '19.4%' },
        desktop: { bottom: '25%', left: '18.95%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { bottom: '33.2%', left: '40.2%' },
        desktop: { bottom: '25%', left: '26.65%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { bottom: '33.2%', left: '61%' },
        desktop: { bottom: '25%', left: '34.35%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { bottom: '17.4%', left: '19.4%' },
        desktop: { bottom: '11.4%', left: '18.95%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { bottom: '17.4%', left: '40.2%' },
        desktop: { bottom: '11.4%', left: '26.65%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { bottom: '17.4%', left: '61%' },
        desktop: { bottom: '11.4%', left: '34.35%' },
      },
    },
  ],
}
