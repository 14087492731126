import { PointWrapperNewYearGame } from './PointWrapperNewYearGame'
import { PointWrapperTreasureIslandGame } from './PointWrapperTreasureIslandGame'
import { PointWrapperKDPayGame } from './PointWrapperKDPayGame'

const PointWrapperByGameId = {
  1: PointWrapperNewYearGame,
  2: PointWrapperTreasureIslandGame,
  3: PointWrapperKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PointWrapper = PointWrapperByGameId[gameId]
