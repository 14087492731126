import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { PATHS } from 'routing/paths'
import { Box, Button } from 'ui/atoms'
import { LayoutDesktop } from 'ui/layout'
import { Notice } from 'ui/organisms'
import { config } from 'config'
import { TextTitleHome } from './TextTitleHome'
import { TextTitleSmallerHome } from './TextTitleSmallerHome'

export const HomeDesktop = () => (
  <LayoutDesktop {...config.backgrounds.HOME_DESKTOP_BACKGROUND}>
    <Notice mt="50px" mb="35px" />

    <Box alt={config.texts.homePage.headingAlt} mb="42px" maxWidth="1050px">
      {config.texts.homePage.headingDesktop.map((textLine, index) => {
        return (
          <TextTitleHome key={index} fontSize="63px">
            {textLine}
          </TextTitleHome>
        )
      })}
    </Box>

    {/* <Box mb="45px" maxWidth="1050px">
      {config.texts.homePage.promoactionFinished.map((textLine, index) => {
        return (
          <TextTitleHome key={index} fontSize="63px">
            {textLine}
          </TextTitleHome>
        )
      })}
    </Box> */}

    {/* <Box mb="45px" maxWidth="1050px">
      <TextTitleSmallerHome fontSize="40px">
        {config.texts.homePage.seeYouInNewGames}
      </TextTitleSmallerHome>
    </Box> */}

    <Box maxWidth="460px">
      <Link to={PATHS.CALENDARS}>
        <Button variant="primary" w="100%" mb="16px" py="18px" fontSize="24px">
          {config.texts.homePage.toCalendars}
        </Button>
      </Link>

      <Link to={PATHS.TICKETS}>
        <Button variant="default" w="100%" py="18px" fontSize="24px">
          Мои билеты
        </Button>
      </Link>
    </Box>
  </LayoutDesktop>
)
