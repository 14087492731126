import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

export const Portal = ({ children, zIndex = 1 }) => {
  const [container] = useState(() => {
    const containerEl = document.createElement('div')
    containerEl.style.position = 'relative'
    containerEl.style.zIndex = zIndex
    return containerEl
  })

  useEffect(() => {
    document.body.appendChild(container)

    return () => {
      document.body.removeChild(container)
    }
  }, [])

  return ReactDOM.createPortal(children, container)
}
