import styled, { css } from 'styled-components'

export const PointWrapperKDPayGame = styled.div`
  position: absolute;
  bottom: ${props => props.position.mobile.bottom || '0'};
  left: ${props => props.position.mobile.left || '0'};

  ${({ $isGiftOpened, $isWideViewport }) => {
    if ($isWideViewport) {
      return $isGiftOpened
        ? css`
            margin-left: 0.75%;
            width: 23.5%;
          `
        : css`
            width: 25%;
          `
    } else {
      return css`
        width: 33.33%;
        height: 24%;
      `
    }
  }}
  border-radius: 9999px;
  background-color: transparent;
  transition: background-color 0.3s ease;

  &:before {
    content: '';

    display: block;
    padding-top: 100%;
  }

  &:after {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: ${({ $imgName }) => `url(${$imgName})`};
    ${({ $isGiftOpeningAnimationShow }) => {
      if ($isGiftOpeningAnimationShow) {
        return css`
          background-size: 134% auto;
          background-position: center bottom -15px;
        `
      } else {
        return css`
          background-size: 125% auto;
          background-position: center bottom -8px;
        `
      }
    }}
    background-repeat: no-repeat;
    transition: opacity 0.3s ease;
  }
`
