import styled, { css } from 'styled-components'

import { Flex } from 'ui/atoms'

export const NavbarWrapperKDPayGame = styled(Flex)`
  position: relative;
  background-color: transparent;

  ${({ $disabled }) => {
    return (
      $disabled &&
      css`
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 10;
          background-color: rgba(0, 0, 0, 0.5);
        }
      `
    )
  }}
`
