import styled from 'styled-components'

import { TextTitle } from 'ui/atoms'

export const CaptionTextTreasureIslandGame = styled(TextTitle)`
  position: absolute;
  left: 50%;
  bottom: auto;
  transform: translateX(-50%);
  min-width: 91px;
  text-shadow: ${({ isWideViewport, isHome, theme }) => {
    if (isHome) {
      return isWideViewport
        ? `1px 2px ${theme.colors.textTertiaryColor}`
        : `1px 1px ${theme.colors.textTertiaryColor}`
    }
  }};
`
