import { PopupInfoCloseNewYearGame } from './PopupInfoCloseNewYearGame'
import { PopupInfoCloseTreasureIslandGame } from './PopupInfoCloseTreasureIslandGame'
import { PopupInfoCloseKDPayGame } from './PopupInfoCloseKDPayGame'

const PopupInfoCloseByGameId = {
  1: PopupInfoCloseNewYearGame,
  2: PopupInfoCloseTreasureIslandGame,
  3: PopupInfoCloseKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupInfoClose = PopupInfoCloseByGameId[gameId]
