import { ButtonVariantsNewYearGame } from './ButtonVariantsNewYearGame'
import { ButtonVariantsTreasureIslandGame } from './ButtonVariantsTreasureIslandGame'
import { ButtonVariantsKDPayGame } from './ButtonVariantsKDPayGame'

const ButtonVariantsByGameId = {
  1: ButtonVariantsNewYearGame,
  2: ButtonVariantsTreasureIslandGame,
  3: ButtonVariantsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ButtonVariants = ButtonVariantsByGameId[gameId]
