import { PreloaderThematicNewYearGame } from './PreloaderThematicNewYearGame'
import { PreloaderThematicTreasureIslandGame } from './PreloaderThematicTreasureIslandGame'
import { PreloaderThematicKDPayGame } from './PreloaderThematicKDPayGame'

const PreloaderThematicByGameId = {
  1: PreloaderThematicNewYearGame,
  2: PreloaderThematicTreasureIslandGame,
  3: PreloaderThematicKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PreloaderThematic = PreloaderThematicByGameId[gameId]
