import { useRef } from 'react'
import useMatchMedia from 'react-use-match-media'

import { TicketsMobile } from './TicketsMobile'
import { TicketsDesktop } from './TicketsDesktop'
import { GlobalStyle } from 'ui/settings/global'
import { useTicketManager } from 'hooks/useTicketManager'

export const Tickets = () => {
  const { tickets, isLoading, error, fetchTickets } = useTicketManager()

  const isWideViewport = useMatchMedia('(min-width: 1200px)')
  const mapsRef = useRef(null)

  return (
    <GlobalStyle>
      {isWideViewport ? (
        <TicketsDesktop
          tickets={tickets}
          isLoading={isLoading}
          hasError={!!error}
          onRetry={fetchTickets}
          isWideViewport={isWideViewport}
          ref={mapsRef}
        />
      ) : (
        <TicketsMobile
          tickets={tickets}
          isLoading={isLoading}
          hasError={!!error}
          onRetry={fetchTickets}
          isWideViewport={isWideViewport}
          ref={mapsRef}
        />
      )}
    </GlobalStyle>
  )
}
