import { useEffect, useMemo, useRef, useState } from 'react'
import useMatchMedia from 'react-use-match-media'
import styled, { css } from 'styled-components'

import { PreloaderThematic } from 'ui/atoms'
import { BoardKeyPreview, BoardPoint } from 'ui/molecules'
import { useBgImage } from './useBgImage'
import { config } from 'config'
import { BoardSceneWrapper } from './BoardSceneWrapper'
import { BoardScene } from './BoardScene'
import { BoardCalendar } from './BoardCalendar'
import { useCalendarGiftPositions } from './useCalendarGiftPositions'
import { boardPreloaderProps } from './boardPreloaderProps'

const BoardWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
  overflow: auto;
`

const BoardPreloader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${props => (props.isVisible ? 1 : 0)};
  pointer-events: ${props => (props.isVisible ? 'all' : 'none')};
`

export const Board = ({
  calendarDetails,
  calendarType = 1,
  isBoardLoading = false,
  giftReceivingAnimationShow = false,
  currentlyOpeningGiftData,
  onKeyDrop = () => {},
  giftPopupOpen = () => {},
  onGiftBoxClick = () => {},
}) => {
  const wrapperRef = useRef(null)
  const [isBgImgLoaded, setIsBgImgLoaded] = useState(false)
  const isWideViewport = useMatchMedia('(min-width: 768px)')
  const isWideTabletViewport = useMatchMedia(
    '(min-width: 768px) and (max-width: 1322px)'
  )
  const calendarPoints = useCalendarGiftPositions(calendarType)

  const bgImage = useBgImage({
    isWideViewport,
    isWideTabletViewport,
    calendarType,
  })

  useEffect(() => {
    if (!!wrapperRef.current) {
      const offsetBottom = wrapperRef.current.scrollHeight

      wrapperRef.current.scrollTop = offsetBottom
    }
  }, [isBgImgLoaded, wrapperRef])

  const renderBoardPoints = () => {
    if (!calendarPoints) {
      return null
    }

    return calendarPoints.map((point, idx) => {
      const calendarItem = calendarDetails?.items.find(
        item => item.index === idx + 1
      )
      return (
        <BoardPoint
          key={point.id}
          calendarType={calendarType}
          calendarItem={calendarItem}
          isWideViewport={isWideViewport}
          {...point}
          dropHandler={onKeyDrop}
          closedBoxClickHandler={onGiftBoxClick}
          giftPopupOpen={giftPopupOpen}
          giftReceivingAnimationShow={giftReceivingAnimationShow}
          currentlyOpeningGiftData={currentlyOpeningGiftData}
        />
      )
    })
  }

  return (
    <BoardWrapper ref={wrapperRef}>
      <BoardPreloader
        {...boardPreloaderProps({ isBoardLoading, giftReceivingAnimationShow })}
      >
        <PreloaderThematic margin="0 auto" />
      </BoardPreloader>

      {!!calendarType && (
        <BoardCalendar
          $isWideTabletViewport={isWideTabletViewport}
          $disabled={isBoardLoading}
        >
          <BoardKeyPreview />
          <BoardSceneWrapper isWideViewport={isWideViewport}>
            <BoardScene
              isWideViewport={isWideViewport}
              calendarType={calendarType}
              src={bgImage}
              alt=""
              onLoad={() => setIsBgImgLoaded(true)}
            />
            {renderBoardPoints()}
          </BoardSceneWrapper>
        </BoardCalendar>
      )}
    </BoardWrapper>
  )
}
