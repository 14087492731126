import { TextTitleRaffleWinnerNewYearGame } from './TextTitleRaffleWinnerNewYearGame'
import { TextTitleRaffleWinnerTreasureIslandGame } from './TextTitleRaffleWinnerTreasureIslandGame'
import { TextTitleRaffleWinnerKDPayGame } from './TextTitleRaffleWinnerKDPayGame'

const TextTitleRaffleWinnerByGameId = {
  1: TextTitleRaffleWinnerNewYearGame,
  2: TextTitleRaffleWinnerTreasureIslandGame,
  3: TextTitleRaffleWinnerKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleRaffleWinner = TextTitleRaffleWinnerByGameId[gameId]
