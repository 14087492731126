import styled from 'styled-components'

import { Box, Flex } from '../atoms'

const LogoFooterWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
`

export const LogoFooterMobile = () => {
  return (
    <LogoFooterWrapper
      justifyContent="space-between"
      flexDirection="column"
      padding="min(16px, 5%)"
    >
      <Flex alignContent="center" justifyContent="center">
        <Box
          as="img"
          w="50%"
          h="auto"
          mb="min(16px, 5%)"
          objectFit="contain"
          src="/img/kdPayGame/kdpay_and_mts_logo.png"
        ></Box>
      </Flex>
      <Flex
        alignContent="center"
        justifyContent="space-between"
        minHeight="20px"
      >
        <Box
          as="img"
          w="22%"
          h="auto"
          objectFit="contain"
          src="/img/kdPayGame/spar-logo.png"
        ></Box>
        <Box
          as="img"
          w="14%"
          h="auto"
          objectFit="contain"
          src="/img/kdPayGame/family-logo.png"
        ></Box>
        <Box
          as="img"
          w="20%"
          h="auto"
          objectFit="contain"
          src="/img/kdPayGame/pobeda-logo.png"
        ></Box>
        <Box
          as="img"
          w="34%"
          h="auto"
          objectFit="contain"
          src="/img/kdPayGame/spar-inline-logo.png"
        ></Box>
      </Flex>
    </LogoFooterWrapper>
  )
}
