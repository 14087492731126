import { KeyDescriptionWrapperNewYearGame } from './KeyDescriptionWrapperNewYearGame'
import { KeyDescriptionWrapperTreasureIslandGame } from './KeyDescriptionWrapperTreasureIslandGame'
import { KeyDescriptionWrapperKDPayGame } from './KeyDescriptionWrapperKDPayGame'

const KeyDescriptionWrapperByGameId = {
  1: KeyDescriptionWrapperNewYearGame,
  2: KeyDescriptionWrapperTreasureIslandGame,
  3: KeyDescriptionWrapperKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const KeyDescriptionWrapper = KeyDescriptionWrapperByGameId[gameId]
