import theme from 'ui/settings/theme'

export const contentTextPropsNewYearGame = {
  mb: '22px',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '130%',
  textAlign: 'center',
  color: theme.colors.white,
}
