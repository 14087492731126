import React, { Fragment } from 'react'
import useMatchMedia from 'react-use-match-media'
import { useLocation, matchPath } from 'react-router-dom'

import { createGlobalStyle } from '@xstyled/styled-components'

import { fonts } from './fonts'
import { preflight } from './preflight'
import { PATHS } from 'routing/paths'

const GlobalStyleInner = createGlobalStyle`
  ${fonts}
  ${preflight}

  body {
    min-height: 100vh;
    min-height: 100dvh;

    font-family: ${props => props.theme.fonts.rubik};
    font-weight: 500;
  }
`

export const GlobalStyle = ({ children }) => {
  const isWideViewport = useMatchMedia('(min-width: 1200px)')
  const { pathname } = useLocation()
  const isHome = !!matchPath({ path: pathname, exact: true }, PATHS.HOME)

  return (
    <Fragment>
      <GlobalStyleInner isMobile={!isWideViewport} isHome={isHome} />
      {children}
    </Fragment>
  )
}
