import { CaptionTextNewYearGame } from './CaptionTextNewYearGame'
import { CaptionTextTreasureIslandGame } from './CaptionTextTreasureIslandGame'
import { CaptionTextKDPayGame } from './CaptionTextKDPayGame'

const CaptionTextByGameId = {
  1: CaptionTextNewYearGame,
  2: CaptionTextTreasureIslandGame,
  3: CaptionTextKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const CaptionText = CaptionTextByGameId[gameId]
