import styled, { css } from 'styled-components'

export const LogoVariantsTreasureIslandGame = ({ variant }) =>
  ({
    default: css`
      top: -152px;
      background-image: url(/img/treasureIslandGame/popup_basic_logo.png);
    `,
    primary: css`
      top: -170px;
      background-image: url(/img/treasureIslandGame/shovel_popup_logo.png);
    `,
  }[variant])
