import { BoardSceneWrapperNewYearGame } from './BoardSceneWrapperNewYearGame'
import { BoardSceneWrapperTreasureIslandGame } from './BoardSceneWrapperTreasureIslandGame'
import { BoardSceneWrapperKDPayGame } from './BoardSceneWrapperKDPayGame'

const BoardSceneWrapperByGameId = {
  1: BoardSceneWrapperNewYearGame,
  2: BoardSceneWrapperTreasureIslandGame,
  3: BoardSceneWrapperKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const BoardSceneWrapper = BoardSceneWrapperByGameId[gameId]
