import themeNewYearGame from './themeNewYearGame'
import themeTreasureIslandGame from './themeTreasureIslandGame'
import themeKDPayGame from './themeKDPayGame'

const themeByGameId = {
  1: themeNewYearGame,
  2: themeTreasureIslandGame,
  3: themeKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
const theme = themeByGameId[gameId]

export default theme
