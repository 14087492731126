import styled from 'styled-components'

export const PopupLogoNewYearGame = styled.img`
  position: absolute;
  top: -146px;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 328px;
`
