import { mainMenuWrapperPropsNewYearGame } from './mainMenuWrapperPropsNewYearGame'
import { mainMenuWrapperPropsTreasureIslandGame } from './mainMenuWrapperPropsTreasureIslandGame'
import { mainMenuWrapperPropsKDPayGame } from './mainMenuWrapperPropsKDPayGame'

const mainMenuWrapperPropsByGameId = {
  1: mainMenuWrapperPropsNewYearGame,
  2: mainMenuWrapperPropsTreasureIslandGame,
  3: mainMenuWrapperPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const mainMenuWrapperProps = mainMenuWrapperPropsByGameId[gameId]
