import styled from 'styled-components'

export const PopupAlertPanelTreasureIslandGame = styled.div`
  display: flex;
  flex-direction: column;

  width: 100vw; // vw вместо % предотвращает схлопывание элемента
  max-width: 328px;
  min-height: 182px;
  padding: 45px 16px 16px 16px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.purple[1]};
`
