import { BottomShadowNewYearGame } from './BottomShadowNewYearGame'
import { BottomShadowTreasureIslandGame } from './BottomShadowTreasureIslandGame'
import { BottomShadowKDPayGame } from './BottomShadowKDPayGame'

export const BottomShadowByGameId = {
  1: BottomShadowNewYearGame,
  2: BottomShadowTreasureIslandGame,
  3: BottomShadowKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const BottomShadow = BottomShadowByGameId[gameId]
