import styled from 'styled-components'

export const TicketListItemTreasureIslandGame = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 107px;
  padding: 8px 8px 8px 16px;
  box-shadow: 0.886243px 2.6587303px 18.61111px 0px #10575e4f;
  background-image: ${({ $isExpired }) =>
    $isExpired
      ? 'radial-gradient(50% 50% at 50% 50%, #C7C7C7 45.95%, #B9B9B9 100%)'
      : 'radial-gradient(50% 50% at 50% 50%, #FFD947 45.95%, #FFC700 100%)'};
  margin-bottom: 12px;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: -32px;
    top: -4px;
    height: 112px;
    width: 42px;
    background-image: ${({ $isExpired }) =>
      $isExpired
        ? 'url(/img/treasureIslandGame/ticket_expired_left_border.png)'
        : 'url(/img/treasureIslandGame/ticket_active_left_border.png)'};
    background-position: left 0 bottom 0;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    right: -30px;
    top: -2px;
    height: 109px;
    width: 42px;
    background-image: ${({ $isExpired }) =>
      $isExpired
        ? 'url(/img/treasureIslandGame/ticket_expired_right_border.png)'
        : 'url(/img/treasureIslandGame/ticket_active_right_border.png)'};
    background-position: left 0 bottom 0;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    min-height: 80px;
    padding: 12px 14px 12px 20px;
  }
`
