import { KeysBoxWrapperNewYearGame } from './KeysBoxWrapperNewYearGame'
import { KeysBoxWrapperTreasureIslandGame } from './KeysBoxWrapperTreasureIslandGame'
import { KeysBoxWrapperKDPayGame } from './KeysBoxWrapperKDPayGame'

const KeysBoxWrapperByGameId = {
  1: KeysBoxWrapperNewYearGame,
  2: KeysBoxWrapperTreasureIslandGame,
  3: KeysBoxWrapperKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const KeysBoxWrapper = KeysBoxWrapperByGameId[gameId]
