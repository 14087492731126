import styled from 'styled-components'

export const CalendarListItemIndicatorNewYearGame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isWideViewport }) => (isWideViewport ? '80px' : '58px')};
  height: ${({ isWideViewport }) => (isWideViewport ? '56px' : '40px')};
  border-radius: ${({ isWideViewport }) => (isWideViewport ? '18px' : '12px')};
  background-image: ${({ isCompleted, isWideViewport }) => {
    if (isWideViewport) {
      return isCompleted
        ? 'linear-gradient(180deg, #68BDAE 0%, #4EA898 100%)'
        : ''
    } else {
      return isCompleted
        ? 'linear-gradient(180deg, #82C6AF 0%, #63BC9E 100%)'
        : ''
    }
  }};
  background-color: ${({ isCompleted, theme }) => {
    return isCompleted ? '' : theme.colors.red[0]
  }};
  box-shadow: ${({ isCompleted }) => {
    return isCompleted
      ? 'inset 0px -4px 0px rgba(0, 0, 0, 0.25)'
      : 'inset 0px -4px 0px rgba(173, 44, 45, 0.82)'
  }};
`
