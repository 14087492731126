import styled from 'styled-components'

export const PopupLogoTreasureIslandGame = styled.div`
  position: absolute;
  top: -152px;
  left: 50%;
  transform: translateX(-50%);
  width: 328px;
  height: 161px;
  background-image: url(/img/treasureIslandGame/ticket_popup_heading.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
`
