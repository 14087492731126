import styled from 'styled-components'

export const BottomShadowTreasureIslandGame = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 2;
  pointer-events: none;
  background-image: linear-gradient(
    180deg,
    rgba(106, 217, 250, 0) 0%,
    #6ad9fa 69.5%
  );
  height: 100px;
  width: 100vw;
`
