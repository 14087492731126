import styled, { css } from 'styled-components'

export const BoardCalendarTreasureIslandGame = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  flex-grow: 1;
  min-height: 100%;
  overflow: auto;

  ${({ $isWideTabletViewport }) => {
    return $isWideTabletViewport
      ? css`
          justify-content: center;
        `
      : css`
          padding-left: 6vw;
        `
  }}

  @media (max-width: 500px) {
    overflow: hidden;
  }
`
