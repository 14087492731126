import { BoardCalendarNewYearGame } from './BoardCalendarNewYearGame'
import { BoardCalendarTreasureIslandGame } from './BoardCalendarTreasureIslandGame'
import { BoardCalendarKDPayGame } from './BoardCalendarKDPayGame'

const BoardCalendarByGameId = {
  1: BoardCalendarNewYearGame,
  2: BoardCalendarTreasureIslandGame,
  3: BoardCalendarKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const BoardCalendar = BoardCalendarByGameId[gameId]
