import { contentSectionBottomPropsNewYearGame } from './contentSectionBottomPropsNewYearGame'
import { contentSectionBottomPropsTreasureIslandGame } from './contentSectionBottomPropsTreasureIslandGame'
import { contentSectionBottomPropsKDPayGame } from './contentSectionBottomPropsKDPayGame'

const contentSectionBottomPropsByGameId = {
  1: contentSectionBottomPropsNewYearGame,
  2: contentSectionBottomPropsTreasureIslandGame,
  3: contentSectionBottomPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const contentSectionBottomProps =
  contentSectionBottomPropsByGameId[gameId]
