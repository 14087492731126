import { BoardSceneNewYearGame } from './BoardSceneNewYearGame'
import { BoardSceneTreasureIslandGame } from './BoardSceneTreasureIslandGame'
import { BoardSceneKDPayGame } from './BoardSceneKDPayGame'

const BoardSceneByGameId = {
  1: BoardSceneNewYearGame,
  2: BoardSceneTreasureIslandGame,
  3: BoardSceneKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const BoardScene = BoardSceneByGameId[gameId]
