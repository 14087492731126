export const CALENDAR_GIFTS_POSITION = {
  1: [
    {
      id: 1,
      position: {
        mobile: { bottom: '75.89%', left: '0%' },
        tablet: { bottom: '75.89%', left: '0%' },
        desktop: { bottom: '77.89%', left: '0%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { bottom: '75.89%', left: '33.33%' },
        tablet: { bottom: '75.89%', left: '25%' },
        desktop: { bottom: '77.89%', left: '25%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { bottom: '75.89%', left: '66.66%' },
        tablet: { bottom: '75.89%', left: '50%' },
        desktop: { bottom: '77.89%', left: '50%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { bottom: '56.76%', left: '0%' },
        tablet: { bottom: '75.89%', left: '75%' },
        desktop: { bottom: '77.89%', left: '75%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { bottom: '56.76%', left: '33.33%' },
        tablet: { bottom: '48.76%', left: '0%' },
        desktop: { bottom: '58.76%', left: '0%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { bottom: '56.76%', left: '66.66%' },
        tablet: { bottom: '48.76%', left: '25%' },
        desktop: { bottom: '58.76%', left: '25%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { bottom: '37.63%', left: '0%' },
        tablet: { bottom: '48.76%', left: '50%' },
        desktop: { bottom: '58.76%', left: '50%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { bottom: '37.63%', left: '33.33%' },
        tablet: { bottom: '48.76%', left: '75%' },
        desktop: { bottom: '58.76%', left: '75%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { bottom: '37.63%', left: '66.66%' },
        tablet: { bottom: '21%', left: '0%' },
        desktop: { bottom: '39.63%', left: '0%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { bottom: '18.5%', left: '0%' },
        tablet: { bottom: '21%', left: '25%' },
        desktop: { bottom: '39.63%', left: '25%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { bottom: '18.5%', left: '33.33%' },
        tablet: { bottom: '21%', left: '50%' },
        desktop: { bottom: '39.63%', left: '50%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { bottom: '18.5%', left: '66.66%' },
        tablet: { bottom: '21%', left: '75%' },
        desktop: { bottom: '39.63%', left: '75%' },
      },
    },
  ],

  2: [
    {
      id: 1,
      position: {
        mobile: { bottom: '75.89%', left: '0%' },
        tablet: { bottom: '75.89%', left: '0%' },
        desktop: { bottom: '77.89%', left: '0%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { bottom: '75.89%', left: '33.33%' },
        tablet: { bottom: '75.89%', left: '25%' },
        desktop: { bottom: '77.89%', left: '25%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { bottom: '75.89%', left: '66.66%' },
        tablet: { bottom: '75.89%', left: '50%' },
        desktop: { bottom: '77.89%', left: '50%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { bottom: '56.76%', left: '0%' },
        tablet: { bottom: '75.89%', left: '75%' },
        desktop: { bottom: '77.76%', left: '75%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { bottom: '56.76%', left: '33.33%' },
        tablet: { bottom: '48.76%', left: '0%' },
        desktop: { bottom: '58.76%', left: '0%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { bottom: '56.76%', left: '66.66%' },
        tablet: { bottom: '48.76%', left: '25%' },
        desktop: { bottom: '58.76%', left: '25%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { bottom: '37.63%', left: '0%' },
        tablet: { bottom: '48.76%', left: '50%' },
        desktop: { bottom: '58.63%', left: '50%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { bottom: '37.63%', left: '33.33%' },
        tablet: { bottom: '48.76%', left: '75%' },
        desktop: { bottom: '58.63%', left: '75%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { bottom: '37.63%', left: '66.66%' },
        tablet: { bottom: '21%', left: '0%' },
        desktop: { bottom: '39.63%', left: '0%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { bottom: '18.5%', left: '0%' },
        tablet: { bottom: '21%', left: '25%' },
        desktop: { bottom: '39.5%', left: '25%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { bottom: '18.5%', left: '33.33%' },
        tablet: { bottom: '21%', left: '50%' },
        desktop: { bottom: '39.5%', left: '50%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { bottom: '18.5%', left: '66.66%' },
        tablet: { bottom: '21%', left: '75%' },
        desktop: { bottom: '39.5%', left: '75%' },
      },
    },
  ],
  3: [
    {
      id: 1,
      position: {
        mobile: { bottom: '75.89%', left: '0%' },
        tablet: { bottom: '75.89%', left: '0%' },
        desktop: { bottom: '77.89%', left: '0%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { bottom: '75.89%', left: '33.33%' },
        tablet: { bottom: '75.89%', left: '25%' },
        desktop: { bottom: '77.89%', left: '25%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { bottom: '75.89%', left: '66.66%' },
        tablet: { bottom: '75.89%', left: '50%' },
        desktop: { bottom: '77.89%', left: '50%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { bottom: '56.76%', left: '0%' },
        tablet: { bottom: '75.89%', left: '75%' },
        desktop: { bottom: '77.76%', left: '75%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { bottom: '56.76%', left: '33.33%' },
        tablet: { bottom: '48.76%', left: '0%' },
        desktop: { bottom: '58.76%', left: '0%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { bottom: '56.76%', left: '66.66%' },
        tablet: { bottom: '48.76%', left: '25%' },
        desktop: { bottom: '58.76%', left: '25%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { bottom: '37.63%', left: '0%' },
        tablet: { bottom: '48.76%', left: '50%' },
        desktop: { bottom: '58.63%', left: '50%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { bottom: '37.63%', left: '33.33%' },
        tablet: { bottom: '48.76%', left: '75%' },
        desktop: { bottom: '58.63%', left: '75%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { bottom: '37.63%', left: '66.66%' },
        tablet: { bottom: '21%', left: '0' },
        desktop: { bottom: '39.63%', left: '0' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { bottom: '18.5%', left: '0%' },
        tablet: { bottom: '21%', left: '25%' },
        desktop: { bottom: '39.5%', left: '25%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { bottom: '18.5%', left: '33.33%' },
        tablet: { bottom: '21%', left: '50%' },
        desktop: { bottom: '39.5%', left: '50%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { bottom: '18.5%', left: '66.66%' },
        tablet: { bottom: '21%', left: '75%' },
        desktop: { bottom: '39.5%', left: '75%' },
      },
    },
  ],
}
