import styled from 'styled-components'

export const PopupPanelNewYearGame = styled.div`
  position: relative;
  width: 100vw; // vw вместо % предотвращает схлопывание элемента
  height: 252px;
  max-width: 328px;
  padding-top: 36px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  background: linear-gradient(180deg, #f2e394 0%, #e7d780 100%);
  box-shadow: 0px 0px 45px 0px #ffd91e69;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: -18px;
    left: 0;
    width: 328px;
    height: 20px;
    background-image: url(/img/ticket_gift_popup_top.png);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -18px;
    left: 0;
    width: 328px;
    height: 20px;
    background-image: url(/img/ticket_gift_popup_bottom.png);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    z-index: 2;
  }
`
