import theme from 'ui/settings/theme'

export const promocodeTextPropsKDPayGame = {
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '19px',
  letterSpacing: '0.5px',
  textAlign: 'center',
  color: theme.colors.black[0],
}
