import styled, { css } from 'styled-components'

export const BoardSceneWrapperNewYearGame = styled.div`
  ${({ isWideViewport }) =>
    isWideViewport
      ? css`
          position: relative;
        `
      : css`
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          bottom: 0;
        `};
  display: flex;
  align-items: flex-end;
  justify-content: center;
`
