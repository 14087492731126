import { useRef, useCallback } from 'react'
import useMatchMedia from 'react-use-match-media'

import { useCalendarsManager } from '../../hooks/useCalendarsManager'
import { CalendarsMobile } from './CalendarsMobile'
import { CalendarsDesktop } from './CalendarsDesktop'
import { GlobalStyle } from 'ui/settings/global'
import { useKeysManager } from 'hooks/useKeysManager'

export const Calendars = () => {
  const {
    calendars,
    isLoading: isCalendarsLoading,
    error: fetchCalendarsError,
    fetchCalendars,
  } = useCalendarsManager()

  const {
    keysCount,
    isLoading: isKeysLoading,
    error: fetchKeysError,
    fetchKeys,
  } = useKeysManager()
  const isWideViewport = useMatchMedia('(min-width: 1200px)')
  const mapsRef = useRef(null)

  const fetchData = useCallback(() => {
    fetchCalendars()
    fetchKeys()
  }, [fetchCalendars, fetchKeys])

  return (
    <GlobalStyle>
      {isWideViewport ? (
        <CalendarsDesktop
          calendars={calendars}
          keysCount={keysCount}
          isLoading={isCalendarsLoading || isKeysLoading}
          hasError={!!fetchCalendarsError || !!fetchKeysError}
          onRetry={fetchData}
          isWideViewport={isWideViewport}
          ref={mapsRef}
        />
      ) : (
        <CalendarsMobile
          calendars={calendars}
          keysCount={keysCount}
          isLoading={isCalendarsLoading || isKeysLoading}
          hasError={!!fetchCalendarsError || !!fetchKeysError}
          onRetry={fetchData}
          isWideViewport={isWideViewport}
          ref={mapsRef}
        />
      )}
    </GlobalStyle>
  )
}
