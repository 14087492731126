import { contentSectionTopPropsNewYearGame } from './contentSectionTopPropsNewYearGame'
import { contentSectionTopPropsTreasureIslandGame } from './contentSectionTopPropsTreasureIslandGame'
import { contentSectionTopPropsKDPayGame } from './contentSectionTopPropsKDPayGame'

const contentSectionTopPropsByGameId = {
  1: contentSectionTopPropsNewYearGame,
  2: contentSectionTopPropsTreasureIslandGame,
  3: contentSectionTopPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const contentSectionTopProps = contentSectionTopPropsByGameId[gameId]
