import { useSelector } from 'react-redux'
import { LayoutMapDesktop } from 'ui/layout'
import { Board, KeyDescription, KeysBox } from 'ui/organisms'
import { config } from 'config'
import { useDesktopBackground } from './useDesktopBackground'

export const CalendarDesktop = ({
  calendarDetails,
  calendarUpdate,
  calendarType,
  isBoardLoading,
  giftReceivingAnimationShow,
  currentlyOpeningGiftData,
  onKeyDrop,
  giftPopupOpen,
  onGiftBoxClick,
}) => {
  const firstVisit = useSelector(state => state.user.firstVisit)
  const background = useDesktopBackground({ calendarType })

  return (
    <LayoutMapDesktop {...background}>
      {firstVisit !== undefined &&
        firstVisit !== null &&
        firstVisit.id === process.env.REACT_APP_GAME_ID &&
        !firstVisit.isKeyDescriptionWasShown && <KeyDescription />}
      <Board
        calendarDetails={calendarDetails}
        calendarType={calendarType}
        isBoardLoading={isBoardLoading}
        giftReceivingAnimationShow={giftReceivingAnimationShow}
        currentlyOpeningGiftData={currentlyOpeningGiftData}
        onKeyDrop={onKeyDrop}
        giftPopupOpen={giftPopupOpen}
        onGiftBoxClick={onGiftBoxClick}
      />

      <KeysBox
        position="absolute"
        top="24px"
        right="84px"
        maxWidth={{ lg: '560px' }}
        calendarUpdate={calendarUpdate}
      />
    </LayoutMapDesktop>
  )
}
