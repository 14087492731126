import { contentPropsNewYearGame } from './contentPropsNewYearGame'
import { contentPropsTreasureIslandGame } from './contentPropsTreasureIslandGame'
import { contentPropsKDPayGame } from './contentPropsKDPayGame'

const contentPropsByGameId = {
  1: contentPropsNewYearGame,
  2: contentPropsTreasureIslandGame,
  3: contentPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const contentProps = contentPropsByGameId[gameId]
