import { backgroundColor } from '@xstyled/styled-components'
import theme from 'ui/settings/theme'

export const raffleDateTextPropsKDPayGame = {
  mb: '24px',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  textAlign: 'center',
  color: theme.colors.purple[0],
}
