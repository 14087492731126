export const CALENDAR_GIFTS_POSITION = {
  1: [
    {
      id: 1,
      position: {
        mobile: { bottom: '75.5%', left: '0%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { bottom: '75.5%', left: '33.33%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { bottom: '75.5%', left: '66.66%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { bottom: '51.5%', left: '0%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { bottom: '51.5%', left: '33.33%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { bottom: '51.5%', left: '66.66%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { bottom: '27.5%', left: '0%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { bottom: '27.5%', left: '33.33%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { bottom: '27.5%', left: '66.66%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { bottom: '3.5%', left: '0%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { bottom: '3.5%', left: '33.33%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { bottom: '3.5%', left: '66.66%' },
      },
    },
  ],
}
