import styled, { css } from 'styled-components'

export const PointWrapperTreasureIslandGame = styled.div`
  position: absolute;
  bottom: ${props => props.position.mobile.bottom || '0'};
  left: ${props => props.position.mobile.left || '0'};

  ${({ $isGiftOpened, $isWideViewport }) => {
    if ($isWideViewport) {
      return $isGiftOpened
        ? css`
            margin-left: 0.75%;
            width: 23.5%;
          `
        : css`
            width: 25%;
          `
    } else {
      return $isGiftOpened
        ? css`
            margin-left: 1.41%;
            width: 30.5%;
          `
        : css`
            width: 33.33%;
          `
    }
  }}
  border-radius: 9999px;
  background-color: transparent;
  transition: background-color 0.3s ease;

  @media (min-width: 768px) {
    bottom: ${props => props.position.tablet.bottom || '0'};
    left: ${props => props.position.tablet.left || '0'};
  }

  @media (min-width: 1322px) {
    bottom: ${props => props.position.desktop.bottom || '0'};
    left: ${props => props.position.desktop.left || '0'};
  }

  &:before {
    content: '';

    display: block;
    padding-top: 100%;
  }

  &:after {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: ${({ $imgName }) => `url(${$imgName})`};
    background-size: contain;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease;
  }
`
