import styled from 'styled-components'

import { TextPageTitle } from 'ui/atoms'

export const TextTitleRaffleWinnerKDPayGame = styled(TextPageTitle)`
  text-shadow: ${({ theme }) => `2px 2px ${theme.colors.black[2]}`};
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
`
