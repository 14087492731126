import { config } from 'config'
import { PopupAlert } from '../molecules'

export const PopupGiftError = ({ isOpened, onClose, onSubmit }) => (
  <PopupAlert
    btnText="Обновить"
    heading={config.texts.popups.giftError.heading}
    message={config.texts.popups.giftError.content}
    isOpened={isOpened}
    onClose={onClose}
    onSubmit={onSubmit}
  />
)
