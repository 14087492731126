import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'

import { Box, Flex, TextPageTitle } from 'ui/atoms'
import { CalendarsAndKeysError, KeysInfo } from 'ui/molecules'
import { LayoutMobile } from 'ui/layout'
import { CalendarList } from 'ui/organisms'
import { config } from 'config'
import { BottomShadow } from './BottomShadow'
import { TextTitleCalendars } from './TextTitleCalendars'
import { useWindowResize } from 'hooks/useWindowResize'

const DEFAULT_KEYS_INFO_HEIGHT_PX = 168
const CALENDAR_LIST_MARGIN_TOP_PX = 30
const CALENDAR_LIST_MARGIN_BOTTOM_PX = 3
const CALENDAR_DELTA_BOTTOM_PX = 6

export const CalendarsMobile = React.forwardRef(
  (
    { calendars, keysCount, isLoading, hasError, onRetry, isWideViewport },
    ref
  ) => {
    const [keysInfoHeightPx, setKeysInfoHeightPx] = useState(
      DEFAULT_KEYS_INFO_HEIGHT_PX
    )

    const keysInfoRef = useRef()

    const handleWindowResize = useCallback(() => {
      if (keysInfoRef.current) {
        const keysInfoDimensions = window.getComputedStyle(keysInfoRef.current)
        setKeysInfoHeightPx(Number(keysInfoDimensions.height.slice(0, -2)))
      }
    }, [hasError, isLoading])

    useWindowResize(handleWindowResize)

    const calendarListHeight = `calc(100% - ${
      keysInfoHeightPx +
      CALENDAR_LIST_MARGIN_TOP_PX +
      CALENDAR_LIST_MARGIN_BOTTOM_PX -
      CALENDAR_DELTA_BOTTOM_PX +
      'px'
    })`

    return (
      <LayoutMobile
        ref={ref}
        {...config.backgrounds.CALENDARS_MOBILE_BACKGROUND}
      >
        <LayoutMobile.Header mb="16px">
          <TextTitleCalendars>
            {config.texts.calendarsPage.pageHeading}
          </TextTitleCalendars>
        </LayoutMobile.Header>

        <LayoutMobile.Content>
          <Box height="100%" maxHeight="100%">
            {!hasError && !isLoading && (
              <KeysInfo
                variant="mobile"
                ref={keysInfoRef}
                keysCount={keysCount}
              />
            )}
            {(isLoading || hasError) && (
              <Flex
                position="relative"
                mt="150px"
                w="100%"
                mx="auto"
                maxWidth="465px"
                flexGrow="1"
                flexDirection="column"
              >
                <CalendarsAndKeysError
                  isLoading={isLoading}
                  hasError={hasError}
                  onRetry={onRetry}
                />
              </Flex>
            )}
            {!isLoading && !hasError && (
              <Box
                h={calendarListHeight}
                maxHeight={calendarListHeight}
                mt={`${CALENDAR_LIST_MARGIN_TOP_PX}px`}
              >
                <CalendarList
                  calendars={calendars}
                  isWideViewport={isWideViewport}
                />
              </Box>
            )}
          </Box>
          <BottomShadow />
        </LayoutMobile.Content>
      </LayoutMobile>
    )
  }
)
