import { useGiftImgNewYearGame } from './useGiftImgNewYearGame'
import { useGiftImgTreasureIslandGame } from './useGiftImgTreasureIslandGame'
import { useGiftImgKDPayGame } from './useGiftImgKDPayGame'

const useGiftImgByGameId = {
  1: useGiftImgNewYearGame,
  2: useGiftImgTreasureIslandGame,
  3: useGiftImgKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useGiftImg = useGiftImgByGameId[gameId]
