import { PopupLogoNewYearGame } from './PopupLogoNewYearGame'
import { PopupLogoTreasureIslandGame } from './PopupLogoTreasureIslandGame'
import { PopupLogoKDPayGame } from './PopupLogoKDPayGame'

const PopupLogoByGameId = {
  1: PopupLogoNewYearGame,
  2: PopupLogoTreasureIslandGame,
  3: PopupLogoKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupLogo = PopupLogoByGameId[gameId]
