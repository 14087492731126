import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isKeysRemiderPopupShownInitially: false,
}

export const popupsSlice = createSlice({
  name: 'popups',
  initialState,
  reducers: {
    setKeysRemiderPopupShownInitiallyTruthy: (state, { payload }) => {
      state.isKeysRemiderPopupShownInitially = true
    },
  },
})

export const { setKeysRemiderPopupShownInitiallyTruthy } = popupsSlice.actions

export const { reducer: userReducer } = popupsSlice

export default popupsSlice
