import React from 'react'
import styled from 'styled-components'

import { Flex } from 'ui/atoms'
import { TicketsError, TicketsInfo } from 'ui/molecules'
import { LayoutDesktop } from 'ui/layout'
import { TicketList } from 'ui/organisms'
import { TextTitleTickets } from './TextTitleTickets'
import { config } from 'config'

export const TicketsDesktop = React.forwardRef(
  ({ tickets, isLoading, hasError, onRetry, isWideViewport }, ref) => {
    return (
      <LayoutDesktop
        ref={ref}
        {...config.backgrounds.TICKETS_DESKTOP_BACKGROUND}
      >
        <TextTitleTickets>Мои билеты</TextTitleTickets>

        {(isLoading || hasError) && (
          <Flex
            position="relative"
            mt="150px"
            w="100%"
            mx="auto"
            maxWidth="465px"
            flexGrow="1"
            flexDirection="column"
          >
            <TicketsError
              isLoading={isLoading}
              hasError={hasError}
              onRetry={onRetry}
            />
          </Flex>
        )}

        {!isLoading && !hasError && (
          <Flex
            position="relative"
            w="100%"
            maxWidth="568px"
            mx="auto"
            mt="40px"
            minHeight="0"
            minWidth="0"
            flexGrow="1"
            flexDirection="column"
          >
            <TicketsInfo variant="desktop" />
            <TicketList tickets={tickets} isWideViewport={isWideViewport} />
          </Flex>
        )}
      </LayoutDesktop>
    )
  }
)
