import theme from 'ui/settings/theme'

export const copyTextPropsTreasureIslandGame = {
  as: 'span',
  display: 'inline-block',
  p: '10px',
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '28px',
  textAlign: 'center',
  color: theme.colors.brown[0],
  textDecoration: 'underline',
  cursor: 'pointer',
}
