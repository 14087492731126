import styled from 'styled-components'

export const BoardKeyPreviewWrapperKDPayGame = styled.div`
  position: relative;
  z-index: 20;
  width: ${({ $width }) => $width + '%'};
  width: 120px;
  max-width: 120px;
  transform: translate(0, 0);

  background-image: url(/img/kdPayGame/coin_glowing.png);
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;

  &:before {
    content: '';

    display: block;
    padding-top: 100%;
  }

  @media (min-width: 768px) {
    width: 120px;
  }

  @media (min-width: 1903px) {
    width: 4.2vw;
  }
`
