import { useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Text } from '../../atoms'
import { ROUTES } from 'routing/routes'
import { CALENDAR_STATUS } from 'constants/calendarGifts'
import { config } from 'config'
import { CalendarListItemIndicator } from './CalendarListItemIndicator'

const CalendarListWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 20px;
    scroll-margin-left: 20px;
  }

  &::-webkit-scrollbar-track {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: #fff;
  }
`

const CalendarListContent = styled.ul``

const CalendarListScroller = styled.div`
  height: 0;
  opacity: 0;
`

const CalendarListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  padding: 8px 8px 8px 16px;
  box-shadow: 0.886243px 2.6587303px 18.61111px 0px #10575e4f;
  border-radius: 12px;
  background: #fff;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    min-height: 80px;
    padding: 12px 14px 12px 20px;
  }
`

const CalendarListLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const IconImg = styled.img`
  width: ${({ isWideViewport }) => {
    return isWideViewport ? '28px' : '20px'
  }};
`

export const CalendarList = ({ calendars, isWideViewport }) => {
  const scrollerRef = useRef()

  if (!calendars || !calendars.length) {
    return null
  }

  return (
    <CalendarListWrapper show={true} width="300px">
      <CalendarListContent>
        {calendars.map((unit, idx) => (
          <CalendarListItem key={idx}>
            <CalendarListLink
              to={ROUTES.CALENDAR.replace('[calendarId]', unit.id)}
            />

            <Text
              fontWeight={{ _: '500', lg: '700' }}
              fontSize={{ _: '18px', lg: '24px' }}
              lineHeight={{ _: '24px', lg: '32px' }}
            >
              {config.texts.calendarsPage.listItemTitle} №
              {calendars.length - idx}
            </Text>

            <CalendarListItemIndicator
              isCompleted={unit.status === CALENDAR_STATUS.FINISHED}
              isWideViewport={isWideViewport}
            >
              {unit.status === CALENDAR_STATUS.FINISHED ? (
                <IconImg
                  isWideViewport={isWideViewport}
                  src={`/img/check.svg`}
                  alt=""
                />
              ) : (
                <IconImg
                  isWideViewport={isWideViewport}
                  src="/img/arrow-right.svg"
                  alt=""
                />
              )}
            </CalendarListItemIndicator>
          </CalendarListItem>
        ))}
      </CalendarListContent>
      <CalendarListScroller ref={scrollerRef} />
    </CalendarListWrapper>
  )
}
