export const fontsNewYearGame = `
  @font-face {
    font-family: "Rubik";
    src:
      local('Rubik-Regular'),
      local('Rubik Regular'), 
      url('/fonts/Rubik-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "Rubik";
    src:
      local('Rubik-Medium'),
      local('Rubik Medium'), 
      url('/fonts/Rubik-Medium.ttf') format('truetype');
    font-style: medium;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: "Rubik";
    src:
      local('Rubik-SemiBold'),
      local('Rubik SemiBold'), 
      url('/fonts/Rubik-SemiBold.ttf') format('truetype');
    font-style: semi-bold;
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: "Rubik";
    src:
      local('Rubik-Bold'),
      local('Rubik Bold'), 
      url('/fonts/Rubik-Bold.ttf') format('truetype');
    font-style: bold;
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rupster Script Free';
    src: url('/fonts/RupsterScriptFree.eot');
    src: local('Rupster Script Free'), local('RupsterScriptFree'),
        url('/fonts/RupsterScriptFree.eot?#iefix') format('embedded-opentype'),
        url('/fonts/RupsterScriptFree.woff2') format('woff2'),
        url('/fonts/RupsterScriptFree.woff') format('woff'),
        url('/fonts/RupsterScriptFree.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
`
