export const useBgImageTreasureIslandGame = ({
  isWideViewport,
  isWideTabletViewport,
}) => {
  let boardBgImage
  if (isWideTabletViewport) {
    boardBgImage = `/img/treasureIslandGame/transparent_board_tablet_bg.png`
  } else if (isWideViewport && !isWideTabletViewport) {
    boardBgImage = `/img/treasureIslandGame/transparent_board_desktop_bg.png`
  } else {
    boardBgImage = `/img/treasureIslandGame/transparent_board_mobile_bg.png`
  }

  return boardBgImage
}
