import { textDescriptionPropsNewYearGame } from './textDescriptionPropsNewYearGame'
import { textDescriptionPropsTreasureIslandGame } from './textDescriptionPropsTreasureIslandGame'
import { textDescriptionPropsKDPayGame } from './textDescriptionPropsKDPayGame'

const textDescriptionPropsByGameId = {
  1: textDescriptionPropsNewYearGame,
  2: textDescriptionPropsTreasureIslandGame,
  3: textDescriptionPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const textDescriptionProps = textDescriptionPropsByGameId[gameId]
