import axios from 'axios'

// Раскомментировать для тестирования на localhost (Заметка: смотри документацию)
// const PROXY_BASE_URL = 'http://localhost:8010/proxy/v1'
// const API_PATH = PROXY_BASE_URL

const host = process.env.REACT_APP_HOST
const API_PATH = `${host}/api/v1`

const axs = axios.create({ baseURL: API_PATH })

export default ({
  url = '/',
  method = 'GET',
  params = {},
  data = {},
  headers = {},
}) => {
  return axs({ url, method, headers, params, data }).then(res => res.data)
}
