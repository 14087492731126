import { ticketsInfoWrapperPropsDesktopNewYearGame } from './ticketsInfoWrapperPropsDesktopNewYearGame'
import { ticketsInfoWrapperPropsDesktopTreasureIslandGame } from './ticketsInfoWrapperPropsDesktopTreasureIslandGame'
import { ticketsInfoWrapperPropsDesktopKDPayGame } from './ticketsInfoWrapperPropsDesktopKDPayGame'

const ticketsInfoWrapperPropsDesktopByGameId = {
  1: ticketsInfoWrapperPropsDesktopNewYearGame,
  2: ticketsInfoWrapperPropsDesktopTreasureIslandGame,
  3: ticketsInfoWrapperPropsDesktopKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketsInfoWrapperPropsDesktop =
  ticketsInfoWrapperPropsDesktopByGameId[gameId]
