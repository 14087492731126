import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ticketsFetch } from 'store/slices/ticketsSlice'

export const useTicketManager = () => {
  const tickets = useSelector(state => state.tickets.data)
  const isLoading = useSelector(state => state.tickets.loading)
  const error = useSelector(state => state.tickets.error)
  const urlSearchParams = useSelector(state => state.user.urlSearchParams)
  const dispatch = useDispatch()

  const fetchTickets = useCallback(() => dispatch(ticketsFetch()), [dispatch])

  useEffect(() => {
    if (
      !!urlSearchParams?.session_id &&
      !!urlSearchParams?.user_id &&
      !isLoading
    ) {
      fetchTickets()
    }
  }, [fetchTickets, urlSearchParams])

  return { tickets, isLoading, error, fetchTickets }
}
