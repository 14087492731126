import { BoardKeyPreviewWrapperNewYearGame } from './BoardKeyPreviewWrapperNewYearGame'
import { BoardKeyPreviewWrapperTreasureIslandGame } from './BoardKeyPreviewWrapperTreasureIslandGame'
import { BoardKeyPreviewWrapperKDPayGame } from './BoardKeyPreviewWrapperKDPayGame'

const BoardKeyPreviewWrapperByGameId = {
  1: BoardKeyPreviewWrapperNewYearGame,
  2: BoardKeyPreviewWrapperTreasureIslandGame,
  3: BoardKeyPreviewWrapperKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const BoardKeyPreviewWrapper = BoardKeyPreviewWrapperByGameId[gameId]
