import styled from 'styled-components'
import { Box, Container, Text } from '../../ui/atoms'

const FooterWrapper = styled(Box).attrs(props => {
  return {
    as: 'footer',
  }
})`
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 32px;
`

export const Footer = () => (
  <FooterWrapper>
    <Container>
      <Text
        as="a"
        href="https://vk.com/spar_kd"
        textDecoration={{ _: 'underline', hover: 'none' }}
        style={{
          display: 'flex',
          alignItems: 'center',
          textDecorationColor: '#ffffff',
        }}
      >
        <Box as="img" src="/img/vk_logo.svg" alt="" mr="8px" />
        <Text color="#ffffff">ВКонтакте</Text>
      </Text>
    </Container>
  </FooterWrapper>
)
