export const fontsKDPayGame = `
  @font-face {
    font-family: "Rubik";
    src:
      local('Rubik-Regular'),
      local('Rubik Regular'), 
      url('/fonts/Rubik-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "Rubik";
    src:
      local('Rubik-Medium'),
      local('Rubik Medium'), 
      url('/fonts/Rubik-Medium.ttf') format('truetype');
    font-style: medium;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: "Rubik";
    src:
      local('Rubik-SemiBold'),
      local('Rubik SemiBold'), 
      url('/fonts/Rubik-SemiBold.ttf') format('truetype');
    font-style: semi-bold;
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: "Rubik";
    src:
      local('Rubik-Bold'),
      local('Rubik Bold'), 
      url('/fonts/Rubik-Bold.ttf') format('truetype');
    font-style: bold;
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: "Rubik";
    src:
      local('Rubik-ExtraBold'),
      local('Rubik Extra Bold'), 
      url('/fonts/Rubik-ExtraBold.ttf') format('truetype');
    font-style: extra-bold;
    font-weight: 800;
    font-display: swap;
  }

  @font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('/fonts/MuseoSansCyrl500.otf') format("opentype");
    src: local('Museo Sans Cyrl'), local('MuseoSansCyrl'),
        url('/fonts/MuseoSansCyrl500.woff') format('woff'),
        url('/fonts/MuseoSansCyrl500.ttf') format('truetype');
    font-weight: 500;
    font-style: medium;
    font-display: swap;
  }

  @font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('/fonts/MuseoSansCyrl700.otf') format("opentype");
    src: local('Museo Sans Cyrl'), local('MuseoSansCyrl'),
        url('/fonts/MuseoSansCyrl700.woff') format('woff'),
        url('/fonts/MuseoSansCyrl700.ttf') format('truetype');
    font-weight: 700;
    font-style: bold;
    font-display: swap;
  }
`
