import React from 'react'
import styled, { css } from 'styled-components'

import { Box, Container } from 'ui/atoms'
import { NavbarMobile } from 'ui/organisms'
import { LogoFooterMobile } from 'ui/organisms/LogoFooterMobile'
import { logoFooterMobileWrapperProps } from './logoFooterMobileWrapperProps'

const LayoutMobileWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
  min-height: 480px;
`

LayoutMobileWrapper.defaultProps = {
  h: '100dvh',
}

const LayoutMobileBodyWrapper = styled(Box)`
  height: calc(100vh - 44px);
  max-height: calc(100vh - 44px);
  height: calc(100dvh - 44px);
  max-height: calc(100dvh - 44px);
  overflow: auto;
`

const LayoutMobileBody = styled(Container)`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 30px;
  ${({ isRulesPage }) =>
    isRulesPage &&
    css`
      max-height: initial;
      height: initial;
    `}
`

const LayoutMobileHeader = styled(Box)``

const LayoutMobileContentBasic = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
  max-height: 100%;
  margin-bottom: 30px;
`

const LayoutMobileContentRaffleWinners = styled.div``

export const LayoutMobile = React.forwardRef(
  ({ children, isRulesPage, isDisplayLogoFooter = false, ...props }, ref) => (
    <LayoutMobileWrapper ref={ref} {...props}>
      <NavbarMobile />

      <LayoutMobileBodyWrapper>
        <LayoutMobileBody
          isRulesPage={isRulesPage}
          className="layout-mobile-body"
        >
          {children}
        </LayoutMobileBody>
      </LayoutMobileBodyWrapper>

      <Box {...logoFooterMobileWrapperProps(isDisplayLogoFooter)}>
        <LogoFooterMobile></LogoFooterMobile>
      </Box>
    </LayoutMobileWrapper>
  )
)

LayoutMobile.Header = LayoutMobileHeader
LayoutMobile.Content = ({ isRaffleWinnersPage = false, children }) => {
  return (
    <>
      {isRaffleWinnersPage ? (
        <LayoutMobileContentRaffleWinners>
          {children}
        </LayoutMobileContentRaffleWinners>
      ) : (
        <LayoutMobileContentBasic>{children}</LayoutMobileContentBasic>
      )}
    </>
  )
}
