import { backgroundColor } from '@xstyled/styled-components'

export const HOME_DESKTOP_BACKGROUND_1 = {
  backgroundImage: 'url(/img/treasureIslandGame/home_desktop_bg_1.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const HOME_DESKTOP_BACKGROUND_2 = {
  backgroundImage: 'url(/img/treasureIslandGame/home_desktop_bg_2.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const HOME_DESKTOP_BACKGROUND_3 = {
  backgroundImage: 'url(/img/treasureIslandGame/home_desktop_bg_3.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const HOME_DESKTOP_BACKGROUND_4 = {
  backgroundImage: 'url(/img/treasureIslandGame/home_desktop_bg_4.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const HOME_DESKTOP_BACKGROUND_5 = {
  backgroundImage: 'url(/img/treasureIslandGame/home_desktop_bg_5.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const HOME_DESKTOP_BACKGROUND = HOME_DESKTOP_BACKGROUND_5

export const HOME_MOBILE_BACKGROUND_1 = {
  backgroundColor: '#579CFF',
  backgroundImage: 'url(/img/treasureIslandGame/home_mobile_bg_1.png)',
  backgroundPosition: '0% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '100vw auto',
  },
}

export const HOME_MOBILE_BACKGROUND_2 = {
  backgroundColor: '#1BCCC4',
  backgroundImage: 'url(/img/treasureIslandGame/home_mobile_bg_2.png)',
  backgroundPosition: '0% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '100vw auto',
  },
}

export const HOME_MOBILE_BACKGROUND_3 = {
  backgroundColor: '#0294ff',
  backgroundImage: 'url(/img/treasureIslandGame/home_mobile_bg_3.png)',
  backgroundPosition: '0% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '100vw auto',
  },
}

export const HOME_MOBILE_BACKGROUND_4 = {
  backgroundColor: '#D768E7',
  backgroundImage: 'url(/img/treasureIslandGame/home_mobile_bg_4.png)',
  backgroundPosition: '0% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '100vw auto',
  },
}

export const HOME_MOBILE_BACKGROUND_5 = {
  backgroundColor: '#55A5FF',
  backgroundImage: 'url(/img/treasureIslandGame/home_mobile_bg_5.png)',
  backgroundPosition: '0% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '100vw auto',
  },
}

export const HOME_MOBILE_BACKGROUND = HOME_MOBILE_BACKGROUND_5

export const RULES_BACKGROUND = {
  backgroundColor: '#0296ff',
}

export const CALENDARS_MOBILE_BACKGROUND = {
  backgroundColor: '#68d9fa',
  backgroundImage: 'url(/img/treasureIslandGame/basic_mobile_bg.png)',
  backgroundPosition: 'left 0 top 0',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '100vw auto',
  },
}

export const CALENDARS_DESKTOP_BACKGROUND = {
  backgroundImage: 'url(/img/treasureIslandGame/basic_desktop_bg.png)',
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const CALENDAR_MOBILE_BACKGROUND_1 = {
  backgroundImage:
    'url(/img/treasureIslandGame/board_bg_1.png), linear-gradient(176.86deg, #3191E8 32.05%, #46E7C4 83.17%)',
  backgroundPosition: 'center bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '900px 1200px, auto auto',
}

export const CALENDAR_MOBILE_BACKGROUND_2 = {
  backgroundImage:
    'url(/img/treasureIslandGame/board_bg_2.png), linear-gradient(90deg,rgba(232,239,255,0.15) 0.05%,rgba(242,246,255,0.09) 21.04%, rgba(252,252,255,0.05) 41.03%, transparent 99.02%), linear-gradient(357.94deg, #46E7C4 17.73%, #3FCDCE 22.37%, #35A0E1 32.18%, #2E84ED 39.4%, #2C7AF2 43.53%, #0E48D6 53.33%)',
  backgroundPosition: 'center bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '900px 1200px, auto, auto',
}

export const CALENDAR_MOBILE_BACKGROUND_3 = {
  backgroundImage:
    'url(/img/treasureIslandGame/board_bg_3.png), linear-gradient(357.94deg, #46E7C4 17.73%, #3FCDCE 22.37%, #35A0E1 32.18%, #2E84ED 39.4%, #2C7AF2 43.53%, #0E48D6 53.33%)',
  backgroundPosition: 'center bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '900px 1200px, auto',
}

export const CALENDAR_DESKTOP_BACKGROUND_1 = {
  backgroundImage: 'url(/img/treasureIslandGame/desktop_board_bg_1.png)',
  backgroundPosition: 'left bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const CALENDAR_DESKTOP_BACKGROUND_2 = {
  backgroundImage: 'url(/img/treasureIslandGame/desktop_board_bg_2.png)',
  backgroundPosition: 'left bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const CALENDAR_DESKTOP_BACKGROUND_3 = {
  backgroundImage: 'url(/img/treasureIslandGame/desktop_board_bg_3.png)',
  backgroundPosition: 'left bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const CALENDAR_TABLET_BACKGROUND_1 = {
  backgroundImage: 'url(/img/treasureIslandGame/desktop_board_bg_1.png)',
  backgroundPosition: 'left bottom 100px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const CALENDAR_TABLET_BACKGROUND_2 = {
  backgroundImage: 'url(/img/treasureIslandGame/desktop_board_bg_2.png)',
  backgroundPosition: 'left bottom 165px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const CALENDAR_TABLET_BACKGROUND_3 = {
  backgroundImage: 'url(/img/treasureIslandGame/desktop_board_bg_3.png)',
  backgroundPosition: 'left bottom 120px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const TICKETS_MOBILE_BACKGROUND = {
  backgroundColor: '#68d9fa',
  backgroundImage: 'url(/img/treasureIslandGame/basic_mobile_bg.png)',
  backgroundPosition: 'left 0 top 0',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '100vw auto',
  },
}

export const TICKETS_DESKTOP_BACKGROUND = {
  backgroundImage: 'url(/img/treasureIslandGame/basic_desktop_bg.png)',
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const SPONSORS_MOBILE_BACKGROUND = {
  backgroundColor: '#68d9fa',
  backgroundImage: 'url(/img/treasureIslandGame/basic_mobile_bg.png)',
  backgroundPosition: 'left 0 top 0',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '100vw auto',
  },
}

export const RAFFLE_WINNERS_MOBILE_BACKGROUND = {
  backgroundColor: '#68d9fa',
  backgroundImage: 'url(/img/treasureIslandGame/basic_mobile_bg.png)',
  backgroundPosition: 'left 0 top 0',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '100vw auto',
  },
}
