import { PopupPanelNewYearGame } from './PopupPanelNewYearGame'
import { PopupPanelTreasureIslandGame } from './PopupPanelTreasureIslandGame'
import { PopupPanelKDPayGame } from './PopupPanelKDPayGame'

const PopupPanelByGameId = {
  1: PopupPanelNewYearGame,
  2: PopupPanelTreasureIslandGame,
  3: PopupPanelKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupPanel = PopupPanelByGameId[gameId]
