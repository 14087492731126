import { KeyImgNewYearGame } from './KeyImgNewYearGame'
import { KeyImgTreasureIslandGame } from './KeyImgTreasureIslandGame'
import { KeyImgKDPayGame } from './KeyImgKDPayGame'

const KeyImgByGameId = {
  1: KeyImgNewYearGame,
  2: KeyImgTreasureIslandGame,
  3: KeyImgKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const KeyImg = KeyImgByGameId[gameId]
