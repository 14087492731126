import { useState } from 'react'

export const usePopupState = ({ initialOpen = false } = {}) => {
  const [isOpen, setIsOpen] = useState(initialOpen)
  const [data, setData] = useState({})

  const onOpen = data => {
    setIsOpen(true)
    if (!!data) {
      setData(data)
    }
  }

  const onClose = () => setIsOpen(false)

  const onToggle = () => setIsOpen(!isOpen)

  return { onOpen, onClose, isOpen, onToggle, data }
}
