import { TextTitleSponsorsNewYearGame } from './TextTitleSponsorsNewYearGame'
import { TextTitleSponsorsTreasureIslandGame } from './TextTitleSponsorsTreasureIslandGame'
import { TextTitleSponsorsKDPayGame } from './TextTitleSponsorsKDPayGame'

const TextTitleSponsorsByGameId = {
  1: TextTitleSponsorsNewYearGame,
  2: TextTitleSponsorsTreasureIslandGame,
  3: TextTitleSponsorsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleSponsors = TextTitleSponsorsByGameId[gameId]
