import styled from 'styled-components'

import { Text } from '../../atoms'
import { converToRussianDateFormat } from 'utils/converToRussianDateFormat'
import { TicketListItem } from './TicketListItem'
import { TicketListContent } from './TicketListContent'
import { ticketValueProps } from './ticketValueProps'
import { ticketRaffleProps } from './ticketRaffleProps'
import { goldenTicketsProps } from './goldenTicketsProps'
import { TicketListWrapper } from './TicketListWrapper'

export const TicketList = ({ tickets, isWideViewport }) => {
  if (!tickets || !tickets.length) {
    return null
  }

  return (
    <TicketListWrapper>
      <TicketListContent>
        {tickets.map((ticket, idx) => {
          const raffleDateRussian = converToRussianDateFormat(
            ticket.raffle_date
          )

          return (
            <TicketListItem key={idx} $isExpired={ticket.is_expired}>
              <Text {...goldenTicketsProps}>Золотой билет</Text>
              <Text {...ticketValueProps}>{ticket.value}</Text>
              <Text {...ticketRaffleProps}>
                Розыгрыш: {raffleDateRussian} г.
              </Text>
            </TicketListItem>
          )
        })}
      </TicketListContent>
    </TicketListWrapper>
  )
}
