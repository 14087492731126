import styled from 'styled-components'

export const PopupPanelTreasureIslandGame = styled.div`
  position: relative;
  width: 100vw; // vw вместо % предотвращает схлопывание элемента
  max-width: 328px;
  padding-top: 40px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  margin-top: 85px;
  border-radius: 8px;
  background-image: radial-gradient(
    58.24% 58.24% at 53.98% 42.3%,
    #ffd74b 42.54%,
    #f2b432 100%
  );
`
