import styled from 'styled-components'
import { useDrag } from 'react-dnd'
import { KeyWrapper } from './KeyWrapper'

export const BoardKey = ({ keyItem }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'key',
    item: { id: keyItem.id },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  return <KeyWrapper ref={drag} isDragging={isDragging} />
}
