import { popupLogoPropsNewYearGame } from './popupLogoPropsNewYearGame'
import { popupLogoPropsTreasureIslandGame } from './popupLogoPropsTreasureIslandGame'
import { popupLogoPropsKDPayGame } from './popupLogoPropsKDPayGame'

const popupInfoLogoPropsByGameId = {
  1: popupLogoPropsNewYearGame,
  2: popupLogoPropsTreasureIslandGame,
  3: popupLogoPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const popupLogoProps = popupInfoLogoPropsByGameId[gameId]
