import { popupInfoLogoPropsNewYearGame } from './popupInfoLogoPropsNewYearGame'
import { popupInfoLogoPropsTreasureIslandGame } from './popupInfoLogoPropsTreasureIslandGame'
import { popupInfoLogoPropsKDPayGame } from './popupInfoLogoPropsKDPayGame'

const popupInfoLogoPropsByGameId = {
  1: popupInfoLogoPropsNewYearGame,
  2: popupInfoLogoPropsTreasureIslandGame,
  3: popupInfoLogoPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const popupInfoLogoProps = popupInfoLogoPropsByGameId[gameId]
