import styled, { css } from 'styled-components'

export const BoardCalendarKDPayGame = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  flex-grow: 1;
  min-height: 100%;
  overflow: auto;

  ${({ $isWideTabletViewport }) => {
    return $isWideTabletViewport
      ? css`
          justify-content: center;
        `
      : css`
          padding-left: 6vw;
        `
  }}

  ${({ $disabled }) =>
    $disabled &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    `};

  @media (max-width: 500px) {
    overflow: hidden;
  }
`
