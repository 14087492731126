import { copyTextPropsNewYearGame } from './copyTextPropsNewYearGame'
import { copyTextPropsTreasureIslandGame } from './copyTextPropsTreasureIslandGame'
import { copyTextPropsKDPayGame } from './copyTextPropsKDPayGame'

const copyTextPropsByGameId = {
  1: copyTextPropsNewYearGame,
  2: copyTextPropsTreasureIslandGame,
  3: copyTextPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const copyTextProps = copyTextPropsByGameId[gameId]
