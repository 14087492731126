import { PopupAlertPanelNewYearGame } from './PopupAlertPanelNewYearGame'
import { PopupAlertPanelTreasureIslandGame } from './PopupAlertPanelTreasureIslandGame'
import { PopupAlertPanelKDPayGame } from './PopupAlertPanelKDPayGame'

const PopupAlertPanelByGameId = {
  1: PopupAlertPanelNewYearGame,
  2: PopupAlertPanelTreasureIslandGame,
  3: PopupAlertPanelKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupAlertPanel = PopupAlertPanelByGameId[gameId]
