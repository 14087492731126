import styled, { css } from 'styled-components'
import { useDrop } from 'react-dnd'
import { useMemo } from 'react'
import { CALENDAR_ITEM_STATUS } from 'constants/calendarGifts'
import { useGiftImg } from './useGiftImg'
import { PointWrapper } from './PointWrapper'

export const BoardPoint = ({
  id: index,
  calendarType,
  calendarItem,
  isWideViewport,
  dropHandler,
  giftPopupOpen,
  closedBoxClickHandler,
  giftReceivingAnimationShow,
  currentlyOpeningGiftData,
  ...props
}) => {
  const [_, drop] = useDrop(
    () => ({
      accept: 'key',
      drop: item =>
        calendarItem && calendarItem.status !== CALENDAR_ITEM_STATUS.OPEN
          ? dropHandler({ keyId: item.id, index })
          : null,
    }),
    [calendarItem, dropHandler]
  )

  const giftImg = useGiftImg({
    index,
    calendarItem,
    giftReceivingAnimationShow,
    currentlyOpeningGiftData,
  })

  const onBoardItemClick = () => {
    if (calendarItem?.status === CALENDAR_ITEM_STATUS.OPEN) {
      giftPopupOpen(calendarItem)
    } else {
      closedBoxClickHandler(index)
    }
  }

  return (
    <PointWrapper
      ref={drop}
      $imgName={giftImg}
      $isGiftOpeningAnimationShow={
        currentlyOpeningGiftData?.itemIndex === index &&
        giftReceivingAnimationShow
      }
      $isGiftOpened={calendarItem?.status === CALENDAR_ITEM_STATUS.OPEN}
      $isWideViewport={isWideViewport}
      calendarType={calendarType}
      onClick={onBoardItemClick}
      {...props}
    />
  )
}
