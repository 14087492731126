export const logoFooterMobileWrapperPropsKDPayGame = isDisplayLogoFooter => {
  return isDisplayLogoFooter
    ? {
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
      }
    : {
        display: 'none',
      }
}
