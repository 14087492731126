import styled from 'styled-components'
import { Popup } from 'ui/atoms'
import { PopupPanel } from './PopupPanel'
import { PopupLogo } from './PopupLogo'

const PopupClose = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;

  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;

  background-color: transparent;
  background-image: url(/img/close.png);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 50%;
`

export const PopupTicketGiftBase = ({
  children,
  isOpened,
  onClose,
  hasLogo = true,
  hasCloseBtn,
}) => (
  <Popup isOpened={isOpened} onClose={onClose} zIndex={2}>
    <PopupPanel>
      {hasLogo && <PopupLogo />}
      {hasCloseBtn && <PopupClose onClick={onClose} />}

      {children}
    </PopupPanel>
  </Popup>
)
