import styled from 'styled-components'
import { Popup } from 'ui/atoms'
import { PopupInfoPanel } from './PopupInfoPanel'
import { PopupInfoLogo } from './PopupInfoLogo'
import { popupInfoLogoProps } from './popupInfoLogoProps'
import { PopupInfoClose } from './PopupInfoClose'

export const PopupInfo = ({
  children,
  isOpened,
  onClose,
  hasLogo = true,
  hasCloseBtn,
  logoVariant = 'default',
}) => (
  <Popup isOpened={isOpened} onClose={onClose}>
    <PopupInfoPanel hasLogo={hasLogo}>
      {hasLogo && (
        <PopupInfoLogo variant={logoVariant} {...popupInfoLogoProps} />
      )}
      {hasCloseBtn && <PopupInfoClose onClick={onClose} />}

      {children}
    </PopupInfoPanel>
  </Popup>
)
