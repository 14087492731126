import { useWideViewportNewYearGame } from './useWideViewportNewYearGame'
import { useWideViewportTreasureIslandGame } from './useWideViewportTreasureIslandGame'
import { useWideViewportKDPayGame } from './useWideViewportKDPayGame'

const useWideViewportByGameId = {
  1: useWideViewportNewYearGame,
  2: useWideViewportTreasureIslandGame,
  3: useWideViewportKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useWideViewport = useWideViewportByGameId[gameId]
