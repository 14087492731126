import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { BASE_ERROR_MESSAGE } from '../../constants/messages'
import api from '../../api'
import { calendarsData } from 'store/stubs'
import { DEFAULT_CLIENT_ID } from 'constants/clientID'

const initialState = {
  data: null,
  loading: false,
  error: null,
}

export const calendarsFetch = createAsyncThunk(
  'calendars/fetch',
  async (_, { rejectWithValue, getState }) => {
    const state = getState()
    const { urlSearchParams } = state.user
    const params = {
      ...urlSearchParams,
      client_id: DEFAULT_CLIENT_ID,
    }

    try {
      const response = await api.calendars.fetch(params)

      return response
        ? response.data.calendars
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const clendarsSlice = createSlice({
  name: 'calendars',
  initialState,
  reducers: {},
  extraReducers: {
    [calendarsFetch.pending]: state => {
      state.loading = true
    },
    [calendarsFetch.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = null
    },
    [calendarsFetch.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export const { reducer: calendarsReducer } = clendarsSlice

export default clendarsSlice
