import styled, { css } from 'styled-components'

export const BoardSceneKDPayGame = styled.img`
  pointer-events: none;
  user-select: none;

  ${({ isWideViewport, calendarType }) => {
    if (isWideViewport) {
      return css`
        width: 71vh;
        height: auto;
      `
    } else {
      return css`
        max-height: calc(100vh - 200px);
        max-height: calc(100dvh - 200px);
        max-width: calc(100vw - 22px);
        height: auto;
      `
    }
  }}
`
