import { useRef } from 'react'
import useMatchMedia from 'react-use-match-media'

import { SponsorsMobile } from './SponsorsMobile'
import { GlobalStyle } from 'ui/settings/global'
import { useTicketManager } from 'hooks/useTicketManager'

export const Sponsors = () => {
  const { tickets, isLoading, error, fetchTickets } = useTicketManager()

  const isWideViewport = useMatchMedia('(min-width: 1200px)')
  const mapsRef = useRef(null)

  return (
    <GlobalStyle>
      <SponsorsMobile
        tickets={tickets}
        isLoading={isLoading}
        hasError={!!error}
        onRetry={fetchTickets}
        isWideViewport={isWideViewport}
        ref={mapsRef}
      />
    </GlobalStyle>
  )
}
