import React, { Fragment } from 'react'

import { Flex, Text } from 'ui/atoms'
import { config } from 'config'
import { keysInfoWrapperPropsMobile } from './keysInfoWrapperPropsMobile'
import { keysInfoWrapperPropsDesktop } from './keysInfoWrapperPropsDesktop'

const KeysInfoContent = ({ keysCount = 0 }) => (
  <Fragment>
    <Text
      as="h2"
      mb="18px"
      fontWeight="700"
      fontSize="20px"
      letterSpacing="0.5px"
      lineHeight="26px"
      textAlign="center"
      color="#fff"
    >
      {config.texts.calendarsPage.availableKeys}: {keysCount}
    </Text>
    <Text
      fontSize="18px"
      fontWeight="400"
      lineHeight="24px"
      textAlign="center"
      color="#fff"
    >
      {config.texts.calendarsPage.keysDescription}
    </Text>
  </Fragment>
)

export const KeysInfo = React.forwardRef(
  ({ keysCount, variant = 'mobile', ...props }, ref) => {
    let keysInfoWrapperProps
    if (variant === 'desktop') {
      keysInfoWrapperProps = keysInfoWrapperPropsDesktop
    } else if (variant === 'mobile') {
      keysInfoWrapperProps = keysInfoWrapperPropsMobile
    }

    return (
      <Flex {...keysInfoWrapperProps} {...props} ref={ref}>
        <KeysInfoContent keysCount={keysCount} />
      </Flex>
    )
  }
)
