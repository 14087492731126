import styled from 'styled-components'

export const PopupInfoCloseTreasureIslandGame = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;

  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;

  background-color: transparent;
  background-image: url(/img/treasureIslandGame/close.png);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 50%;
`
