import styled from 'styled-components'
import { TextPageTitle } from 'ui/atoms'

export const TextTitleCalendarsNewYearGame = styled(TextPageTitle)`
  font-size: 56px;
  font-weight: 500;
  line-height: 130%;
  letterspacing: 0.5px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-shadow: ${({ theme }) => `4px 4px ${theme.colors.blue[1]}`};
`
