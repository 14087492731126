import theme from 'ui/settings/theme'

export const headingTextPropsTreasureIslandGame = {
  mb: '22px',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '130%',
  textAlign: 'center',
  color: theme.colors.brown[0],
}
