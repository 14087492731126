import { keysBoxWrapperPropsNewYearGame } from './keysBoxWrapperPropsNewYearGame'
import { keysBoxWrapperPropsTreasureIslandGame } from './keysBoxWrapperPropsTreasureIslandGame'
import { keysBoxWrapperPropsKDPayGame } from './keysBoxWrapperPropsKDPayGame'

const keysBoxWrapperPropsByGameId = {
  1: keysBoxWrapperPropsNewYearGame,
  2: keysBoxWrapperPropsTreasureIslandGame,
  3: keysBoxWrapperPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const keysBoxWrapperProps = keysBoxWrapperPropsByGameId[gameId]
