import styled, { css } from 'styled-components'

import { Box } from 'ui/atoms'

export const KeysBoxWrapperKDPayGame = styled(Box)`
  position: relative;

  ${({ $disabled }) => {
    return (
      $disabled &&
      css`
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 10;
          background-color: rgba(0, 0, 0, 0.5);
        }
      `
    )
  }}
`
