import useMatchMedia from 'react-use-match-media'
import cloneDeep from 'lodash.clonedeep'

import { LayoutMapMobile } from 'ui/layout'
import { Board, KeyDescription, KeysBox } from 'ui/organisms'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { config } from 'config'
import { useMobileBackground } from './useMobileBackground'

export const CalendarMobile = ({
  calendarDetails,
  calendarUpdate,
  calendarType,
  isBoardLoading,
  giftReceivingAnimationShow,
  currentlyOpeningGiftData,
  onKeyDrop,
  giftPopupOpen,
  onGiftBoxClick,
}) => {
  const firstVisit = useSelector(state => state.user.firstVisit)
  const background = useMobileBackground({ calendarType })

  return (
    <LayoutMapMobile {...background}>
      {firstVisit !== undefined &&
        firstVisit !== null &&
        firstVisit.id === process.env.REACT_APP_GAME_ID &&
        !firstVisit.isKeyDescriptionWasShown && <KeyDescription />}
      <Board
        calendarDetails={calendarDetails}
        calendarType={calendarType}
        isBoardLoading={isBoardLoading}
        giftReceivingAnimationShow={giftReceivingAnimationShow}
        currentlyOpeningGiftData={currentlyOpeningGiftData}
        onKeyDrop={onKeyDrop}
        giftPopupOpen={giftPopupOpen}
        onGiftBoxClick={onGiftBoxClick}
      />

      <KeysBox calendarUpdate={calendarUpdate} />
    </LayoutMapMobile>
  )
}
