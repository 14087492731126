import { RaffleWinnerItemTextNewYearGame } from './RaffleWinnerItemTextNewYearGame'
import { RaffleWinnerItemTextTreasureIslandGame } from './RaffleWinnerItemTextTreasureIslandGame'
import { RaffleWinnerItemTextKDPayGame } from './RaffleWinnerItemTextKDPayGame'

const RaffleWinnerItemTextByGameId = {
  1: RaffleWinnerItemTextNewYearGame,
  2: RaffleWinnerItemTextTreasureIslandGame,
  3: RaffleWinnerItemTextKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const RaffleWinnerItemText = RaffleWinnerItemTextByGameId[gameId]
