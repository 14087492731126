import { useCalendarGiftPositionsNewYearGame } from './useCalendarGiftPositionsNewYearGame'
import { useCalendarGiftPositionsTreasureIslandGame } from './useCalendarGiftPositionsTreasureIslandGame'
import { useCalendarGiftPositionsKDPayGame } from './useCalendarGiftPositionsKDPayGame'

const useCalendarGiftPositionsByGameId = {
  1: useCalendarGiftPositionsNewYearGame,
  2: useCalendarGiftPositionsTreasureIslandGame,
  3: useCalendarGiftPositionsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useCalendarGiftPositions = useCalendarGiftPositionsByGameId[gameId]
