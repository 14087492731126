import theme from 'ui/settings/theme'

export const HOME_DESKTOP_BACKGROUND_1 = {
  backgroundImage: 'url(/img/kdPayGame/home_desktop_bg_1.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const HOME_DESKTOP_BACKGROUND_2 = {
  backgroundImage: 'url(/img/kdPayGame/home_desktop_bg_2.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const HOME_DESKTOP_BACKGROUND_3 = {
  backgroundImage: 'url(/img/kdPayGame/home_desktop_bg_3.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const HOME_DESKTOP_BACKGROUND_4 = {
  backgroundImage: 'url(/img/kdPayGame/home_desktop_bg_4.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const HOME_DESKTOP_BACKGROUND_5 = {
  backgroundImage: 'url(/img/kdPayGame/home_desktop_bg_5.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const HOME_DESKTOP_BACKGROUND = HOME_DESKTOP_BACKGROUND_5

export const HOME_MOBILE_BACKGROUND = {
  backgroundColor: theme.colors.yellow[3],
  backgroundImage: 'url(/img/kdPayGame/home_mobile_bg.png)',
  backgroundPosition: {
    _: 'center bottom min(18px, 16%)',
    xs: 'center bottom 5px',
    xsWide: 'center bottom -10px',
  },
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '100vw auto',
  },
}

export const RULES_BACKGROUND = {
  backgroundColor: theme.colors.yellow[3],
}

export const CALENDARS_MOBILE_BACKGROUND = {
  backgroundColor: theme.colors.yellow[3],
}

export const CALENDARS_DESKTOP_BACKGROUND = {
  backgroundImage: 'url(/img/kdPayGame/basic_desktop_bg.png)',
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const CALENDAR_MOBILE_BACKGROUND = {
  backgroundImage: 'url(/img/kdPayGame/board_bg.png)',
  backgroundColor: '#FF9D29',
  backgroundPosition: 'center bottom 36px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '600px auto',
}

export const CALENDAR_DESKTOP_BACKGROUND_1 = {
  backgroundImage: 'url(/img/kdPayGame/desktop_board_bg_1.png)',
  backgroundPosition: 'left bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const CALENDAR_DESKTOP_BACKGROUND_2 = {
  backgroundImage: 'url(/img/kdPayGame/desktop_board_bg_2.png)',
  backgroundPosition: 'left bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const CALENDAR_DESKTOP_BACKGROUND_3 = {
  backgroundImage: 'url(/img/kdPayGame/desktop_board_bg_3.png)',
  backgroundPosition: 'left bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const CALENDAR_TABLET_BACKGROUND_1 = {
  backgroundImage: 'url(/img/kdPayGame/desktop_board_bg_1.png)',
  backgroundPosition: 'left bottom 100px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const CALENDAR_TABLET_BACKGROUND_2 = {
  backgroundImage: 'url(/img/kdPayGame/desktop_board_bg_2.png)',
  backgroundPosition: 'left bottom 165px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const CALENDAR_TABLET_BACKGROUND_3 = {
  backgroundImage: 'url(/img/kdPayGame/desktop_board_bg_3.png)',
  backgroundPosition: 'left bottom 120px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const TICKETS_MOBILE_BACKGROUND = {
  backgroundColor: theme.colors.yellow[3],
}

export const TICKETS_DESKTOP_BACKGROUND = {
  backgroundImage: 'url(/img/kdPayGame/basic_desktop_bg.png)',
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export const SPONSORS_MOBILE_BACKGROUND = {
  backgroundColor: '#68d9fa',
  backgroundImage: 'url(/img/kdPayGame/basic_mobile_bg.png)',
  backgroundPosition: 'left 0 top 0',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '100vw auto',
  },
}

export const RAFFLE_WINNERS_MOBILE_BACKGROUND = {
  backgroundColor: theme.colors.yellow[3],
}
