import styled from 'styled-components'

export const TicketListItemNewYearGame = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 107px;
  padding: 8px 8px 8px 16px;
  box-shadow: 0.886243px 2.6587303px 18.61111px 0px #10575e4f;
  background-image: ${({ $isExpired }) =>
    $isExpired
      ? 'linear-gradient(180deg, #C5C5C5 0%, #B0B0B0 100%)'
      : 'linear-gradient(180deg, #f2e394 0%, #e8d780 100%)'};
  margin-bottom: 12px;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: -19px;
    top: 0;
    height: 107px;
    width: 20px;
    background-image: ${({ $isExpired }) =>
      $isExpired
        ? 'url(/img/newYearGame/ticket_expired_left_border.png)'
        : 'url(/img/newYearGame/ticket_active_left_border.png)'};
    background-position: left 0 bottom 0;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    right: -19px;
    top: 0;
    height: 107px;
    width: 20px;
    background-image: ${({ $isExpired }) =>
      $isExpired
        ? 'url(/img/newYearGame/ticket_expired_right_border.png)'
        : 'url(/img/newYearGame/ticket_active_right_border.png)'};
    background-position: left 0 bottom 0;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    min-height: 80px;
    padding: 12px 14px 12px 20px;
  }
`
