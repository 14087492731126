import styled from 'styled-components'

export const ActionDescriptionWrapperKDPayGame = styled.div`
  width: 257px;
  height: 105px;
  margin: 0 auto 20px auto;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.white};

  &::-webkit-scrollbar {
    width: 20px;
    scroll-margin-left: 20px;
  }

  &::-webkit-scrollbar-track {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.4);
  }

  &::-webkit-scrollbar-thumb {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`
