import styled from 'styled-components'
import { TextPageTitle } from 'ui/atoms'

export const TextTitleCalendarsKDPayGame = styled(TextPageTitle)`
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  text-shadow: ${({ theme }) => `2px 2px ${theme.colors.textTertiaryColor}`};
  text-transform: uppercase;
`
