import { useMemo } from 'react'

export const useBgImageNewYearGame = ({ isWideViewport, calendarType }) => {
  const bgImage = useMemo(() => {
    const bgImageFileName = isWideViewport
      ? `board-${calendarType}`
      : `board-mobile-${calendarType}`

    return `/img/newYearGame/${bgImageFileName}.png`
  }, [isWideViewport, calendarType])

  return bgImage
}
