import styled from 'styled-components'
import {
  flexboxes,
  backgrounds,
  layout,
  space,
  sizing,
  borders,
  position,
  color,
} from '@xstyled/styled-components'

export const Flex = styled.div(
  { display: 'flex' },
  flexboxes,
  backgrounds,
  layout,
  space,
  sizing,
  borders,
  position,
  color
)
