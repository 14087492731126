import React from 'react'
import styled from 'styled-components'

import { Box, TextPageTitle } from '../../ui/atoms'
import { LayoutMobile } from '../../ui/layout'
import { config } from 'config'
import { TextTitleSponsors } from './TextTitleSponsors'

export const SponsorsMobile = React.forwardRef(
  ({ tickets, isLoading, hasError, onRetry, isWideViewport }, ref) => {
    return (
      <LayoutMobile
        ref={ref}
        {...config.backgrounds.SPONSORS_MOBILE_BACKGROUND}
      >
        <LayoutMobile.Header mb="16px">
          <TextTitleSponsors
            fontSize="40px"
            fontWeight="500"
            lineHeight="130%"
            letterSpacing="0.5px"
            textAlign="center"
            mx="auto"
          >
            Товары спонсоры
          </TextTitleSponsors>
        </LayoutMobile.Header>

        <LayoutMobile.Content>
          <Box border="20px solid #ffffff" borderRadius="20px">
            <img src="/img/sponsor_goods_mobile.jpg" width="100%" alt="" />
          </Box>
        </LayoutMobile.Content>
      </LayoutMobile>
    )
  }
)
