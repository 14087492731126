import styled from 'styled-components'

export const PopupInfoPanelNewYearGame = styled.div`
  position: relative;
  width: 100vw; // vw вместо % предотвращает схлопывание элемента
  max-width: 328px;
  padding-top: ${({ hasLogo }) => (hasLogo ? '40px' : '60px')};
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  margin-top: 85px;
  border-radius: 32px;
  background: linear-gradient(180deg, #ea843f 2.52%, #d36d29 52.86%);
`
