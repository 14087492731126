import { PopupInfoPanelNewYearGame } from './PopupInfoPanelNewYearGame'
import { PopupInfoPanelTreasureIslandGame } from './PopupInfoPanelTreasureIslandGame'
import { PopupInfoPanelKDPayGame } from './PopupInfoPanelKDPayGame'

const PopupInfoPanelByGameId = {
  1: PopupInfoPanelNewYearGame,
  2: PopupInfoPanelTreasureIslandGame,
  3: PopupInfoPanelKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupInfoPanel = PopupInfoPanelByGameId[gameId]
