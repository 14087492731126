import { backgroundColor } from '@xstyled/styled-components'
import theme from 'ui/settings/theme'

export const ticketValueTextPropsKDPayGame = {
  padding: '8px 5px',
  marginBottom: '16px',
  marginLeft: '10px',
  marginRight: '10px',
  fontSize: '25px',
  fontWeight: '600',
  lineHeight: '34px',
  textAlign: 'center',
  color: theme.colors.purple[0],
  background: theme.colors.white,
  borderRadius: '12px',
}
