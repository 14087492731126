import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { BASE_ERROR_MESSAGE } from '../../constants/messages'
import api from '../../api'
import { ticketsData } from 'store/stubs'
import { DEFAULT_CLIENT_ID } from 'constants/clientID'

const initialState = {
  data: null,
  loading: false,
  error: null,
}

export const ticketsFetch = createAsyncThunk(
  'tickets/fetch',
  async (_, { rejectWithValue, getState }) => {
    const state = getState()
    const { urlSearchParams } = state.user
    const params = {
      ...urlSearchParams,
      client_id: DEFAULT_CLIENT_ID,
    }

    try {
      const response = await api.tickets.fetch(params)

      return response
        ? response.data.tickets
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  extraReducers: {
    [ticketsFetch.pending]: state => {
      state.loading = true
    },
    [ticketsFetch.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = null
    },
    [ticketsFetch.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export const { reducer: ticketsReducer, name } = ticketsSlice

export default ticketsSlice
