import styled from 'styled-components'

export const PopupPanelTreasureIslandGame = styled.div`
  position: relative;
  width: 100vw; // vw вместо % предотвращает схлопывание элемента
  max-width: 328px;
  padding-top: 40px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  margin-top: 85px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.brown[1]};
`
