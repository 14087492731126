import { TextTitleCalendarsNewYearGame } from './TextTitleCalendarsNewYearGame'
import { TextTitleCalendarsTreasureIslandGame } from './TextTitleCalendarsTreasureIslandGame'
import { TextTitleCalendarsKDPayGame } from './TextTitleCalendarsKDPayGame'

const TextTitleCalendarsByGameId = {
  1: TextTitleCalendarsNewYearGame,
  2: TextTitleCalendarsTreasureIslandGame,
  3: TextTitleCalendarsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleCalendars = TextTitleCalendarsByGameId[gameId]
