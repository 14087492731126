import styled from 'styled-components'

import { TextTitle } from 'ui/atoms'

export const RaffleWinnerItemTextTreasureIslandGame = styled(TextTitle)`
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
  text-shadow: ${({ theme }) => `2px 2px rgba(0, 0, 0, 0.25)`};
`
