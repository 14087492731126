import { fontsNewYearGame } from './fontsNewYearGame'
import { fontsTreasureIslandGame } from './fontsTreasureIslandGame'
import { fontsKDPayGame } from './fontsKDPayGame'

const fontsByGameId = {
  1: fontsNewYearGame,
  2: fontsTreasureIslandGame,
  3: fontsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const fonts = fontsByGameId[gameId]
