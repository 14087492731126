import styled, { css } from 'styled-components'

export const BoardCalendarNewYearGame = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
  min-height: 100%;
  overflow: auto;

  @media (max-width: 500px) {
    overflow: hidden;
  }
`
