import { TextTitleHomeNewYearGame } from './TextTitleHomeNewYearGame'
import { TextTitleHomeTreasureIslandGame } from './TextTitleHomeTreasureIslandGame'
import { TextTitleHomeKDPayGame } from './TextTitleHomeKDPayGame'

const TextTitleHomeByGameId = {
  1: TextTitleHomeNewYearGame,
  2: TextTitleHomeTreasureIslandGame,
  3: TextTitleHomeKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleHome = TextTitleHomeByGameId[gameId]
