import styled, { css } from 'styled-components'

export const ButtonVariantsTreasureIslandGame = ({
  theme,
  variant,
  isLoading,
}) =>
  ({
    default: css`
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.white};
      color: ${theme.colors.red[0]};
      box-shadow: inset 0px -4px 0px ${theme.colors.grey[0]};
    `,
    primary: css`
      background-color: ${theme.colors.red[3]};
      border-color: ${theme.colors.red[3]};
      color: ${theme.colors.white};
      box-shadow: 0px -4px 0px 0px #c00608 inset;

      ${isLoading &&
      css`
        &:after {
          border: 2px solid ${theme.colors.white};
        }
      `}
    `,
    secondary: css`
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.white};
      color: ${theme.colors.brown[0]};
      box-shadow: 0px -4px 0px 0px ${theme.colors.grey[0]} inset;
    `,
    tertiary: css`
      background-color: ${({ theme }) => theme.colors.blue[4]};
      border-color: ${({ theme }) => theme.colors.blue[4]};
      color: #fff;
    `,
    quaternary: css`
      background-color: ${theme.colors.brown[0]};
      border-color: ${theme.colors.white};
      color: ${theme.colors.white};
      box-shadow: 0px -4px 0px 0px ${theme.colors.red[2]} inset;
    `,
    quinary: css`
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.white};
      color: ${theme.colors.purple[1]};
      box-shadow: 0px -4px 0px 0px ${theme.colors.red[2]} inset;
    `,
    secondaryMapView: css`
      background-color: #3c7625;
      border-color: #3c7625;
      color: #fff;
    `,
    outlined: css`
      border-width: 2px;
      border-style: solid;
      background-color: transparent;
      border-color: ${theme.colors.white};
      color: ${theme.colors.white};
      font-weight: 700;

      &:hover {
        background-color: ${theme.colors.white};
        color: ${theme.colors.red[0]};
      }
    `,
  }[variant])
