import { promocodeTextPropsNewYearGame } from './promocodeTextPropsNewYearGame'
import { promocodeTextPropsTreasureIslandGame } from './promocodeTextPropsTreasureIslandGame'
import { promocodeTextPropsKDPayGame } from './promocodeTextPropsKDPayGame'

const promocodeTextPropsByGameId = {
  1: promocodeTextPropsNewYearGame,
  2: promocodeTextPropsTreasureIslandGame,
  3: promocodeTextPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const promocodeTextProps = promocodeTextPropsByGameId[gameId]
