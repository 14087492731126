import { ENDPOINTS } from './endpoints'
import fetcher from './fetcher'

const api = {
  calendars: {
    fetch: params => fetcher({ url: ENDPOINTS.CALENDARS, params }),
  },
  keys: {
    fetch: params => fetcher({ url: ENDPOINTS.KEYS, params }),
  },
  tickets: {
    fetch: params => fetcher({ url: ENDPOINTS.TICKETS, params }),
  },
  calendar: {
    giftReceiving: (data, params) =>
      fetcher({ url: ENDPOINTS.GIFT_RECEIVING, params, method: 'POST', data }),
    calendarDetails: params =>
      fetcher({ url: ENDPOINTS.CALENDAR_DETAILS, params }),
  },
  couponCount: {
    fetch: params => fetcher({ url: ENDPOINTS.COUPONCOUNT, params }),
  },
  finalDraw: {
    fetch: params => fetcher({ url: ENDPOINTS.FINALLIST, params }),
    realize: params => fetcher({ url: ENDPOINTS.COUPONREALIZE_FINAL, params }),
  },
  weeklyDraw: {
    fetch: params => fetcher({ url: ENDPOINTS.COUPONLIST, params }),
    realize: params => fetcher({ url: ENDPOINTS.COUPONREALIZE, params }),
  },
}

export default api
