import styled from 'styled-components'
import { TextPageTitle } from 'ui/atoms'

export const TextTitleCalendarsTreasureIslandGame = styled(TextPageTitle)`
  font-size: 60px;
  font-weight: 800;
  line-height: 130%;
  letterspacing: 0.5px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-shadow: ${({ theme }) => `2px 2px ${theme.colors.black[3]}`};
`
