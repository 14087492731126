import { ticketValueTextPropsNewYearGame } from './ticketValueTextPropsNewYearGame'
import { ticketValueTextPropsTreasureIslandGame } from './ticketValueTextPropsTreasureIslandGame'
import { ticketValueTextPropsKDPayGame } from './ticketValueTextPropsKDPayGame'

const ticketValueTextPropsByGameId = {
  1: ticketValueTextPropsNewYearGame,
  2: ticketValueTextPropsTreasureIslandGame,
  3: ticketValueTextPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketValueTextProps = ticketValueTextPropsByGameId[gameId]
