import styled from 'styled-components'
import { Link, useLocation, matchPath } from 'react-router-dom'

import { PATHS } from 'routing/paths'
import { getNavLinkBasedOnLocation } from 'utils/navigation'
import { Box, Button, Container, Flex } from 'ui/atoms'
import { MainMenu } from '..'
import { NavbarDesktopWrapper } from './NavbarDesktopWrapper'

const NavbarDesktopContent = styled(Container)`
  display: flex;
  align-items: center;
`

const NavbarDesktopMenu = styled(MainMenu)`
  margin-left: auto;
`

const FamilyLogo = styled(Box)`
  position: relative;
  top: -4px;
`

export const NavbarDesktop = () => {
  const { pathname } = useLocation()
  const isHome = !!matchPath({ path: pathname, exact: true }, PATHS.HOME)

  return (
    <NavbarDesktopWrapper minHeight="68px">
      <NavbarDesktopContent>
        {!isHome && (
          <Link to={getNavLinkBasedOnLocation(pathname)}>
            <Button
              as="div"
              variant="tertiary"
              mr="42px"
              px="16px"
              py="8px"
              borderRadius="12px"
            >
              <Box as="img" src="img/nav-left.svg" alt="" mr="12px" />
              Назад
            </Button>
          </Link>
        )}

        <Flex>
          <Box as="img" src="/img/spar_logo.svg" alt="Spar" mr="37px" />
          <Box as="img" src="/img/pobeda_logo.svg" alt="Победа" mr="37px" />
          <FamilyLogo as="img" src="/img/semya_logo.svg" alt="Семья" />
        </Flex>

        <NavbarDesktopMenu />
      </NavbarDesktopContent>
    </NavbarDesktopWrapper>
  )
}
