import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import styled from 'styled-components'
import cn from 'classnames'

import 'swiper/css'

import { Text } from 'ui/atoms'
import { BoardKey } from 'ui/molecules'
import { KeysCarouselControl } from './KeysCarouselControl'
import { config } from 'config'

const KeysCarouselWrapper = styled.div`
  display: flex;
  align-items: center;

  .swiper-slide {
    width: auto;
  }
`

const KEYS_CAROUSEL_OPTIONS = {
  modules: [Navigation],
  spaceBetween: 24,
  slidesPerView: 'auto',
  allowTouchMove: false,
  navigation: { nextEl: '.carousel-next', prevEl: '.carousel-prev' },
}

const KeysCarouselContent = ({ keys }) => (
  <Swiper {...KEYS_CAROUSEL_OPTIONS}>
    {keys?.map((keyItem, index) => (
      <SwiperSlide key={index}>
        <BoardKey keyItem={keyItem} />
      </SwiperSlide>
    ))}
  </Swiper>
)

const KeysCarouselEmptyMessage = () => (
  <Text
    style={{ flexGrow: 1 }}
    fontSize="14px"
    fontWeight="400"
    lineHeight="22px"
    textAlign="center"
    color="#fff"
  >
    {config.texts.keysCarousel.emptyMessage}
  </Text>
)

export const KeysCarousel = ({ keys, keysCount }) => (
  <KeysCarouselWrapper>
    <KeysCarouselControl
      className={cn('carousel-prev', {
        'swiper-button-disabled': keysCount === 0,
      })}
    >
      <img src="/img/angle-left.svg" alt="" />
    </KeysCarouselControl>

    {keysCount > 0 ? (
      <KeysCarouselContent keys={keys} />
    ) : (
      <KeysCarouselEmptyMessage />
    )}

    <KeysCarouselControl
      className={cn('carousel-next', {
        'swiper-button-disabled': keysCount === 0,
      })}
    >
      <img src="/img/angle-right.svg" alt="" />
    </KeysCarouselControl>
  </KeysCarouselWrapper>
)
