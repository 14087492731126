import theme from 'ui/settings/theme'

export const yourGiftTextPropsTreasureIslandGame = {
  mb: '16px',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '26px',
  letterSpacing: '0.5px',
  textAlign: 'center',
  color: theme.colors.brown[0],
}
