import styled from 'styled-components'

export const CalendarListItemIndicatorTreasureIslandGame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isWideViewport }) => (isWideViewport ? '80px' : '58px')};
  height: ${({ isWideViewport }) => (isWideViewport ? '56px' : '40px')};
  border-radius: ${({ isWideViewport }) => (isWideViewport ? '18px' : '12px')};
  background-color: ${({ isCompleted, theme }) => {
    return isCompleted ? theme.colors.green[0] : theme.colors.red[1]
  }};
  box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25);
`
