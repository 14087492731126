import { goldenTicketsPropsNewYearGame } from './goldenTicketsPropsNewYearGame'
import { goldenTicketsPropsTreasureIslandGame } from './goldenTicketsPropsTreasureIslandGame'
import { goldenTicketsPropsKDPayGame } from './goldenTicketsPropsKDPayGame'

const goldenTicketsPropsByGameId = {
  1: goldenTicketsPropsNewYearGame,
  2: goldenTicketsPropsTreasureIslandGame,
  3: goldenTicketsPropsKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const goldenTicketsProps = goldenTicketsPropsByGameId[gameId]
