import useMatchMedia from 'react-use-match-media'

export const PreloaderThematicKDPayGame = ({ margin }) => {
  const isSmartPhoneViewport = useMatchMedia('(max-width: 500px)')
  return (
    <lottie-player
      src="/lottie/kdPayGame/coin.json"
      background="transparent"
      speed="1"
      style={{
        width: '700px',
        height: '700px',
        transform: isSmartPhoneViewport ? 'scale(1.7)' : null,
      }}
      loop
      autoplay
    ></lottie-player>
  )
}

PreloaderThematicKDPayGame.defaultProps = {}
