import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setKeysRemiderPopupShownInitiallyTruthy } from 'store/slices/popupsSlice'

export const usePopupsManager = ({ isAutoLoad = true } = {}) => {
  const isKeysRemiderPopupShownInitially = useSelector(
    state => state.popups.isKeysRemiderPopupShownInitially
  )
  const dispatch = useDispatch()

  const setKeysRemiderPopupShownInitiallyTruthyWrapper = useCallback(
    () => dispatch(setKeysRemiderPopupShownInitiallyTruthy()),
    [dispatch]
  )

  return {
    isKeysRemiderPopupShownInitially,
    setKeysRemiderPopupShownInitiallyTruthy:
      setKeysRemiderPopupShownInitiallyTruthyWrapper,
  }
}
