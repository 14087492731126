import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  firstVisit: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUrlParams: (state, { payload }) => {
      state.urlSearchParams = { ...state.urlSearchParams, ...payload }
    },
    clearUrlParams: state => {
      state.urlSearchParams = {}
    },
    setFirstVisit: (state, { payload }) => {
      state.firstVisit = payload
    },
    setIsRulesPopupWasShown: (state, { payload }) => {
      if (state.firstVisit) {
        state.firstVisit.isRulesPopupWasShown = payload
      }
    },
    setIsKeyDescriptionWasShown: (state, { payload }) => {
      if (state.firstVisit) {
        state.firstVisit.isKeyDescriptionWasShown = payload
      }
    },
  },
})

export const {
  setUrlParams,
  clearUrlParams,
  setFirstVisit,
  setIsRulesPopupWasShown,
  setIsKeyDescriptionWasShown,
} = userSlice.actions

export const { reducer: userReducer } = userSlice

export default userSlice
