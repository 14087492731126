import { ticketsInfoWrapperPropsMobileNewYearGame } from './ticketsInfoWrapperPropsMobileNewYearGame'
import { ticketsInfoWrapperPropsMobileTreasureIslandGame } from './ticketsInfoWrapperPropsMobileTreasureIslandGame'
import { ticketsInfoWrapperPropsMobileKDPayGame } from './ticketsInfoWrapperPropsMobileKDPayGame'

const ticketsInfoWrapperPropsMobileByGameId = {
  1: ticketsInfoWrapperPropsMobileNewYearGame,
  2: ticketsInfoWrapperPropsMobileTreasureIslandGame,
  3: ticketsInfoWrapperPropsMobileKDPayGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketsInfoWrapperPropsMobile =
  ticketsInfoWrapperPropsMobileByGameId[gameId]
