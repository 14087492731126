import { defaultTheme } from '@xstyled/styled-components'

const breakpoints = [
  '375px',
  '570px',
  '768px',
  '992px',
  '1200px',
  '1322px',
  '1536px',
]

const theme = {
  ...defaultTheme,
  screens: {
    _: 0,
    xs: breakpoints[0],
    xsWide: breakpoints[1],
    sm: breakpoints[2],
    md: breakpoints[3],
    lg: breakpoints[4],
    lgWide: breakpoints[5],
    xl: breakpoints[6],
  },
  mq: {
    xs: `(min-width: ${breakpoints[0]})`,
    xsWide: `(min-width: ${breakpoints[1]})`,
    sm: `(min-width: ${breakpoints[2]})`,
    md: `(min-width: ${breakpoints[4]})`,
    lg: `(min-width: ${breakpoints[5]})`,
    lgWide: `(min-width: ${breakpoints[5]})`,
    xl: `(min-width: ${breakpoints[6]})`,
  },
  fonts: {
    rubik: 'Rubik, sans-serif',
    rupsterscriptfree: 'Rupster Script Free, sans-serif',
    museosanscyrl: 'Museo Sans Cyrl, sans-serif',
  },
  colors: {
    white: '#fff',
    black: {
      0: '#000000',
      1: 'rgba(0, 0, 0, 0.1)',
      2: 'rgba(0, 0, 0, 0.2)',
      3: 'rgba(0, 0, 0, 0.25)',
    },
    red: {
      0: '#ED472F',
      1: '#E63535',
      2: '#701616',
      3: '#DE221F',
      4: '#B41703',
      5: '#F4302C',
    },
    blue: {
      0: '#103040',
      1: '#4F85AC',
      2: '#144055',
      3: 'rgba(20, 64, 85, 0.8)',
      4: '#83B6DC',
    },
    green: {
      0: '#71A82B',
    },
    brown: {
      0: '#8A3122',
      1: '#FEBC77',
      2: 'rgba(138, 49, 34, 0.4)',
      3: '#c7611d',
      4: '#E18A2F',
    },
    purple: {
      0: '#5E133C',
      1: '#331D1A',
      2: '#391329',
    },
    grey: {
      0: '#EBF1F1',
    },
    yellow: {
      0: 'rgba(241, 226, 147, 0.1)',
      1: '#FF9D29',
      2: '#eab621',
      3: '#FF9C27',
    },
    textPrimaryColor: '#d16600',
    textSecondaryColor: '#144055',
    textTertiaryColor: 'rgba(0, 0, 0, 0.2)',
    textQuaternaryColor: 'rgba(0, 0, 0, 0.25)',
  },
}

export default theme
